import { ActionIcon } from "@/components";
import { variantProvider } from "@/app";
import { capitalize } from "@codeleap/common";
import { APIClient } from "@/services";

type SocialButtonProps = {
  platform: APIClient.Session.SocialProviderTypes;
  handleSocial: (args: string) => void;
};

export const SocialButton: React.FC<SocialButtonProps> = ({
  platform,
  handleSocial,
}) => {
  const _platform = capitalize(platform);

  const platformButtonStyle = styles?.[`button${_platform}`];

  return (
    <ActionIcon
      css={[styles.button, platformButtonStyle]}
      variants={["originalColor"]}
      icon={platform}
      onPress={() => handleSocial(platform)}
      iconProps={{
        color: platformButtonStyle?.color,
      }}
    />
  );
};

const BUTTON_HEIGHT = 48;
const BORDER_WIDTH = 1;
const FACEBOOK_ICON_COLOR = "#1877F2";

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    button: {
      flex: 1,
      height: BUTTON_HEIGHT,
      ...theme.spacing.padding(2),
      borderRadius: theme.borderRadius.small,
      backgroundColor: theme.colors.transparent,
      ...theme.presets.center,
      transition: "background 0.2s",

      "&:hover": {
        backgroundColor: theme.colors.neutral2,
      },
    },
    buttonGoogle: {
      ...theme.border.neutral5({ width: BORDER_WIDTH }),
    },
    buttonFacebook: {
      color: FACEBOOK_ICON_COLOR,
      ...theme.border.create({
        color: FACEBOOK_ICON_COLOR,
        width: BORDER_WIDTH,
      }),
    },
    buttonApple: {
      color: theme.colors.neutral10,
      ...theme.border.neutral10({ width: BORDER_WIDTH }),
    },
  }),
  true,
);
