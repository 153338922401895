import { ConversationResponse } from "@/types/chat";
import { Conversation } from "@/pages/list/chat";

export function mapConversationsResponse(
  response: ConversationResponse[],
  profileId: string,
): Conversation[] {
  return response.map((item) => {
    const lastMessage = item.messages.sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    )[0];
    const receiver = item.participants.find(
      (x) => x.id.toString() !== profileId,
    );
    return {
      id: item.id,
      sender: {
        id: receiver.id,
        email: receiver.email,
        fullName: receiver.full_name,
        avatar: receiver.avatar,
        phone: receiver.phone,
        role: receiver.role,
      },
      hotel: item.hotel,
      lastMessageSender: {
        id: lastMessage.sender.id,
        email: lastMessage.sender.email,
        fullName: lastMessage.sender.full_name,
        avatar: lastMessage.sender.avatar,
        phone: lastMessage.sender.phone,
        role: lastMessage.sender.role,
      },
      messages: item.messages,
      lastMessage: lastMessage?.text,
      lastMessageRead: lastMessage?.is_read,
      time: lastMessage?.timestamp,
      conversationImage: item.hotel.media[0]?.file,
    };
  });
}
