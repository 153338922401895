import { Profile, retrieve } from "./views";
import { QueryKeys } from "./queryKeys";
import { AppStatus } from "@/redux";
import type Firebase from "firebase/auth";
import { getFirebase } from "../../firebaseApp";

const SCOPE = "Social Authentication";

type FirebaseUser = Firebase.User;

export const CredentialProviders = {
  google: async () => {
    try {
      const firebase = await getFirebase();
      const res = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider());

      return res.credential;
    } catch (err) {
      logger.error({ err });
    }
  },
  facebook: async () => {
    try {
      const firebase = await getFirebase();
      const res = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider());

      return res.credential;
    } catch (err) {
      logger.error({ err });
    }
  },
  apple: async () => {
    try {
      const firebase = await getFirebase();
      const provider = new firebase.auth.OAuthProvider("apple.com");

      provider.addScope("email");
      provider.addScope("name");

      const res = await firebase.auth().signInWithPopup(provider);

      return res.credential;
    } catch (err) {
      console.error(err);
    }
  },
};

export type SocialProviderTypes = keyof typeof CredentialProviders;

type User = Partial<Pick<Profile, "first_name" | "last_name" | "email" | "id">>;

export const authProvidersList = Object.keys(
  CredentialProviders,
) as SocialProviderTypes[];

export type TrySocialLoginArgs = {
  withProvider?: SocialProviderTypes;
};

type SocialLoginOptions = {
  provider: SocialProviderTypes;
};

const handleUserIsNotInDjango = async (firebaseUser, provider) => {
  const user: User = {
    id: firebaseUser.uid,
    email: firebaseUser.email,
  };

  if (provider === "facebook") {
    user.email = firebaseUser.providerData?.[0]?.email;
  }

  const nameParts = firebaseUser.displayName.split(" ");

  user.first_name = nameParts[0];

  if (nameParts.length > 1) {
    user.last_name = nameParts[1];
  }

  return user;
};

const handleUserIsInDjango = async () => {
  await QueryKeys.me.refresh();
  AppStatus.authFinished();
};

export async function socialLogin(options: SocialLoginOptions) {
  try {
    AppStatus.set("loading");

    const { provider } = options;

    let firebaseUser: FirebaseUser = null;

    try {
      const firebase = await getFirebase();
      const socialCredential = await CredentialProviders[provider]();

      const res = await firebase.auth().signInWithCredential(socialCredential);

      firebaseUser = res.user;
    } catch (e) {
      logger.error("Error on socialLogin " + provider, e, SCOPE);
      return;
    }

    let profile = null;

    try {
      profile = await retrieve();
    } catch (e) {
      logger.info(
        "Failed to get profile from API during socialLogin " + provider,
        e,
        SCOPE,
      );
    }

    const userExistsOnBackend = !!profile;

    if (userExistsOnBackend) {
      handleUserIsInDjango();
    }

    if (firebaseUser && !userExistsOnBackend) {
      return handleUserIsNotInDjango(firebaseUser, provider);
    }
  } finally {
    AppStatus.set("idle");
  }
}
