import React from "react";
import lottie, {
  AnimationConfigWithPath,
  AnimationConfigWithData,
} from "lottie-web";
import { onMount, useRef } from "@codeleap/common";
import { View } from "@/components";
import { ViewProps } from "@codeleap/web";

export type AnimationsProps = ViewProps<"div"> & {
  animation: AnimationConfigWithData<"svg">["animationData"];
  height?: number;
  width?: number;
  loop?: boolean;
  animationProps?:
    | AnimationConfigWithPath<"svg">
    | AnimationConfigWithData<"svg">;
  autoplay?: boolean;
};

export const Animations: React.FC<AnimationsProps> = (props) => {
  const {
    animation,
    height,
    width,
    loop = true,
    autoplay = true,
    animationProps = {},
    ...containerProps
  } = props;

  const animationContainerRef = useRef<HTMLDivElement>();

  onMount(() => {
    const _animation = lottie.loadAnimation({
      container: animationContainerRef.current,
      renderer: "svg",
      loop,
      autoplay,
      animationData: animation,
      ...animationProps,
    });

    return () => _animation.destroy();
  });

  return <View ref={animationContainerRef} {...containerProps} />;
};
