import React from "react";

type FirebaseInstance = any;

let initialized = false;

type FirebaseFunction<T> = (fb: FirebaseInstance) => T;

/*
  Firebase does not work in a server environment as it requires access to the browser's fetch API
  This is a workaround to allow us to use Firebase in the browser without failing Gatbsy's build process
*/

const config = {
  apiKey: "AIzaSyDgujHDl7D9hS30EVbtJfIj1hnbouQr5u4",
  authDomain: "x-bellhopp.firebaseapp.com",
  projectId: "x-bellhopp",
  storageBucket: "x-bellhopp.appspot.com",
  messagingSenderId: "386965846313",
  appId: "1:386965846313:web:ea4b7399dd8e5bbd211f38",
  measurementId: "G-Z6Y48QKPL7",
};

let cachedInstance: FirebaseInstance = null;

export async function getFirebase() {
  const fbApp = await import("firebase/compat/app");
  await import("firebase/compat/auth");

  const _firebase = fbApp.default;

  if (!initialized) {
    _firebase.initializeApp(config);
    cachedInstance = fbApp;
    initialized = true;
  }

  return _firebase;
}

export const useFirebase = () => {
  const [firebase, setFirebase] = React.useState<FirebaseInstance | null>(
    cachedInstance,
  );

  React.useEffect(() => {
    if (!firebase) {
      getFirebase().then(setFirebase);
    }
  }, []);

  return firebase;
};

export async function getFirebaseAuth() {
  const firebaseAuth = (await getFirebase()).auth;
  return firebaseAuth;
}
