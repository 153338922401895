import {
  Theme,
  variantProvider,
  Settings,
  AppImages,
  assignTextStyle,
} from "@/app";
import { View, Logo, Text, Link, Icon } from "@/components";
import { format } from "date-fns";
import { CenterWrapper } from "./CenterWrapper";

const socialMediaLinks = [
  {
    url: Settings.Social.FaceURL,
    icon: "facebook",
    size: 24,
  },
  {
    url: Settings.Social.instagramURL,
    icon: "instagram",
    size: 24,
  },
];

const SectionDisclaimers = () => (
  <View css={styles.disclaimersWrapper}>
    <Text css={styles.disclaimerText}>Visit us in Camden, London, UK.</Text>
    <Text css={styles.disclaimerText}>
      CodeLeap Ltd. is registered in England&nbsp;&&nbsp;Wales,
      company&nbsp;no.&nbsp;11967804.
    </Text>
    <Text variants={["inline"]} css={styles.disclaimerText}>
      This website uses&thinsp;
      <Link css={styles.disclaimerText} to="/terms/licence">
        third-party assets and software
      </Link>
      . By using our website you agree to the&thinsp;
      <Link css={styles.disclaimerText} to="/terms/website">
        terms of use
      </Link>
      .
    </Text>
  </View>
);

function SectionContact() {
  const year = format(new Date(), "yyyy");

  return (
    <View
      variants={[
        "column",
        "fullHeight",
        "justifySpaceBetween",
        "alignEnd",
        "gap:1",
      ]}
      responsiveVariants={{
        mid: ["alignCenter"],
      }}
    >
      <Link
        text={Settings.ContactINFO.ContactEMAIL}
        to={`mailto:${Settings.ContactINFO.ContactEMAIL}`}
        css={styles.contactText}
      />
      <Link
        text={Settings.ContactINFO.ContactPHONE}
        to={`tel:${Settings.ContactINFO.ContactPHONE}`}
        css={styles.contactText}
      />
      <Text css={styles.contactText} text={`©${year} CodeLeap Ltd.`} />
      <Text css={styles.contactText} text={"Made with ♥️ in London, UK"} />
    </View>
  );
}

const SectionSocialMedia = () => (
  <View variants={["gap:2"]}>
    {socialMediaLinks.map((media, i) => (
      <Link key={i + media.icon} to={media.url}>
        <Icon
          // @ts-ignore
          name={media.icon}
          color={Theme.colors.light.neutral1}
          size={media.size}
        />
      </Link>
    ))}
  </View>
);

export const Footer = () => {
  const logoImage = AppImages.AppLogoMobile;

  return (
    <CenterWrapper
      responsiveVariants={{ ["small"]: ["hidden"] }}
      variants={["bg:neutral9", "paddingVertical:3"]}
    >
      <View variants={["flex", "column"]}>
        <View variants={["column", "flex", "gap:5"]}>
          <View variants={["row", "justifySpaceBetween", "gap:5"]}>
            <Logo
              style={styles.logoImage}
              responsive={false}
              switchServerOnPress
              image={logoImage}
            />
            <SectionDisclaimers />
            <SectionSocialMedia />
          </View>
        </View>
      </View>
    </CenterWrapper>
  );
};

const logoSize = 32;
const minHeight = 420;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      position: "relative",
      zIndex: 1,
      display: "flex",
      backgroundColor: theme.colors.primary3,
      width: "100%",
      minHeight: minHeight,
      ...theme.spacing.paddingHorizontal(10),
      ...theme.spacing.paddingVertical(5),

      [Theme.media.down("mid")]: {
        ...theme.spacing.paddingHorizontal(2),
        ...theme.spacing.paddingVertical(5),
      },
    },
    disclaimerText: {
      ...assignTextStyle("p3")(theme).text,
      color: theme.colors.neutral1,

      [Theme.media.down("mid")]: {
        alignSelf: "center",
        ...theme.presets.textCenter,
      },
    },
    footerNavText: {
      ...assignTextStyle("h5")(theme).text,
      color: theme.colors.neutral10,
      textDecoration: "none",
    },
    logoImage: {
      width: logoSize,
      marginBottom: theme.spacing.value(3),
      alignSelf: "flex-start",

      [Theme.media.down("mid")]: {
        alignSelf: "center",
      },
    },
    contactText: {
      ...assignTextStyle("p1")(theme).text,
      color: theme.colors.neutral10,
      fontWeight: "300",
      textDecoration: "none",
    },
    disclaimersWrapper: {
      flex: 3,
      display: "flex",
      flexDirection: "column",
    },
  }),
  true,
);
