import { variantProvider } from "@/app";
import {
  AuthenticatedScreen,
  CenterWrapper,
  Page,
  Sidebar,
  SignOut,
  Text,
  View,
} from "@/components";
import { APIClient } from "@/services";
import { onUpdate, useState } from "@codeleap/common";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { mapConversationsResponse } from "@/services/api/chat/chatMappers";
import { Toaster, newMessageToast } from "@/components/Toast/NewMessageToast";
import { Conversation } from "@/pages/list/chat";

const BREAKPOINT = "mid";
const REFRESH_INTERVAL = 10_000;
export const PageBase = ({ children, ...props }) => {
  const location = useLocation();
  const page = location?.pathname.split("/")[2];
  const { profile } = APIClient.Session.useSession();
  let conversations: Conversation[] = [];
  const [unreadMessages, setUnreadMessages] = useState<string[]>([]);

  onUpdate(() => {
    if (profile && !profile.hotel?.verified) {
      navigate("/auth/wait-approval/");
    }
  }, [profile]);

  onUpdate(() => {
    const fetchData = async (isRefresh: boolean) => {
      if (profile?.hotel?.id && profile?.id) {
        const newConversations = (
          await APIClient.Chat.listConversations(profile?.hotel.id.toString())
        ).filter((x) => x?.messages?.length > 0);
        const mappedNewConversations = mapConversationsResponse(
          newConversations,
          profile?.id.toString(),
        );
        if (isRefresh) {
          mappedNewConversations.forEach((newConversation) => {
            const conversation = conversations?.find(
              (x) => x.id === newConversation.id,
            );
            console.log({ conversation, newConversation });
            const isNewMessage =
              !conversation ||
              newConversation?.messages?.length >
                conversation?.messages?.length;
            const isGuestMessage =
              newConversation?.lastMessageSender.role === "guest";
            if (
              isNewMessage &&
              isGuestMessage &&
              !newConversation?.lastMessageRead
            ) {
              newMessageToast(
                {
                  sender: newConversation.sender.fullName,
                  message: newConversation.lastMessage,
                  avatar:
                    newConversation.sender.avatar ||
                    newConversation.sender.fullName,
                },
                {},
                "top-right",
                async () =>
                  await navigate("/list/messages", {
                    state: { conversationId: newConversation.id },
                  }),
              );
              if (!unreadMessages.includes(newConversation.id)) {
                setUnreadMessages([...unreadMessages, newConversation.id]);
              }
            }
          });
          conversations = mappedNewConversations;
        }
      }
    };
    fetchData(false);

    const intervalId = setInterval(() => fetchData(true), REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [profile]);
  return (
    <AuthenticatedScreen>
      <Page
        centerContent={false}
        pageTitle={page === "guests" ? "Guest List" : "Guest Notifications"}
        showHeader={false}
        showFooter={false}
        {...props}
      >
        <CenterWrapper
          responsiveVariants={{ [BREAKPOINT]: ["column"] }}
          styles={{ wrapper: styles.container }}
          variants={["paddingVertical:5", "fullHeight"]}
        >
          <Sidebar
            page={page}
            hotel={profile?.hotel}
            hasUnreadMessages={unreadMessages.length > 0}
            unreadMessagesCount={unreadMessages.length}
          />
          <View
            style={styles.container}
            responsiveVariants={{ [BREAKPOINT]: ["marginLeft:0"] }}
            variants={["marginLeft:4", "column", "fullWidth", "fullHeight"]}
          >
            <View up={"mid"} variants={["alignCenter", "justifySpaceBetween"]}>
              <View variants={["column"]}>
                <Text text="Hello" variants={["h5", "color:neutral6"]} />
                <Text
                  text={profile?.hotel.title}
                  variants={["h2", "color:neutral9"]}
                />
              </View>

              <SignOut />
            </View>

            {children}
          </View>
        </CenterWrapper>
        <Toaster />
      </Page>
    </AuthenticatedScreen>
  );
};

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    container: {
      overflow: "hidden",
    },
    content: {
      ...theme.presets.scrollXY,
    },
  }),
  true,
);
