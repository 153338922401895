import { PaginationResponse, QueryManager, ReactQuery } from "@codeleap/common";
import { api } from "@/app";
import { queryClient } from "../queryClient";

const BASE_URL = "/hotel/reservation/";

type ReservationsFilters = {
  q?: string;
};

const queryKeys = {
  reservations: ["reservations"],
};

export async function deleteReservation(reservation) {
  await api.delete(`${BASE_URL}${reservation.id}/`);

  await queryClient.client.refetchQueries({
    queryKey: reservationsManager.queryKeys.list,
  });

  return reservation;
}

export const reservationsManager = new QueryManager({
  itemType: {} as any,
  name: "reservations",
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: ReservationsFilters) => {
    const response = await api.get<PaginationResponse<any>>(BASE_URL, {
      params: {
        limit: 100,
        offset: 0,
        ...filters,
      },
    });

    return response.data;
  },

  updateItem: async (data) => {
    const response = await api.patch<PaginationResponse<any>>(
      `${BASE_URL}${data.id}/`,
      data,
    );

    await queryClient.client.refetchQueries({
      queryKey: queryClient.queryKey(["reservations"]).key,
    });

    return response.data;
  },
});

export const useReservations = () => {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.reservations,
  });

  return {
    query,
  };
};
