import { useState, useRef } from "@codeleap/common";
import { React, Theme, variantProvider } from "@/app";
import { AppStatus, useAppSelector } from "@/redux";
import {
  Modal,
  View,
  Checkbox,
  Text,
  ActionIcon,
  AuthForm,
  AuthFormComponents,
} from "@/components";
import { useMediaQueryDown } from "@/utils";

type AuthModalProps = {};

export const AuthModal: React.FC<AuthModalProps> = (props) => {
  const { ...modalProps } = props;

  const status = useAppSelector((store) => store.AppStatus.status);

  const [form, setForm] = useState("signup");

  const isOpen = useAppSelector((store) => store.AppStatus.modals.auth);

  const loginRef = useRef(null);
  const signupRef = useRef(null);
  const forgotRef = useRef(null);

  const isMobile = useMediaQueryDown("small");

  return (
    <Modal
      showClose={false}
      variants={["centered", "scroll", isMobile && "fullscreen", "center"]}
      visible={isOpen && status !== "loading"}
      onClose={() => AppStatus.setModal("auth")}
      {...modalProps}
    >
      <View css={[styles.formWrapper]}>
        <View
          variants={["flex", "row", "marginBottom:1", "justifySpaceBetween"]}
        >
          <Text
            variants={["h2"]}
            text={form === "login" ? "Welcome back" : "Get started"}
          />
          <ActionIcon
            name={"x"}
            variants={["minimal", "primary3"]}
            iconProps={{
              size: Theme.values.iconSize[3],
            }}
            onPress={() => AppStatus.setModal("auth")}
          />
        </View>
        <AuthForm
          ref={loginRef}
          modal={true}
          title="Welcome back"
          subTitle="Create your account now"
          type="login"
          visible={form === "login"}
          onFormSwitch={() => setForm("signup")}
          onAuthSuccess={() => AppStatus.setModal("auth")}
          fields={[
            "email",
            "password",
            {
              name: "remember",
              component: Checkbox,
              variants: ["left"],
            },
          ]}
          buttons={[
            {
              text: "Forgot Password",
              onPress: () => setForm("forgot"),
              variants: ["outline", "large"],
            },
          ]}
          Footer={
            <AuthFormComponents.RedirectModal
              text={"Don't have an account?"}
              textAction={"Sign Up"}
              onPress={() => setForm("signup")}
            />
          }
        />
        <AuthForm
          ref={signupRef}
          modal={true}
          visible={form === "signup"}
          type="signup"
          title="Get started"
          subTitle="Create your account now"
          submitText="Create account"
          onFormSwitch={() => setForm("login")}
          onAuthSuccess={() => AppStatus.setModal("auth")}
          fields={[
            // 'first_name',
            // 'last_name',
            "email",
            {
              name: "password",
              extraProps: {
                visibilityToggle: true,
              },
            },
            {
              name: "repeatPassword",
              extraProps: {
                visibilityToggle: true,
              },
            },
            // {
            //   name: 'terms',
            //   component: Checkbox,
            //   variants: ['left'],
            // },
          ]}
          Footer={
            <AuthFormComponents.RedirectModal
              text={"Have an account?"}
              textAction={"Login"}
              onPress={() => setForm("login")}
            />
          }
        />
        <AuthForm
          ref={forgotRef}
          modal={true}
          visible={form === "forgot"}
          type="forgot"
          showSocial={false}
          onFormSwitch={() => null}
          onAuthSuccess={() => setForm("login")}
          fields={["email"]}
          title="Recover password"
          Footer={
            <AuthFormComponents.RedirectModal
              text={"Go back to "}
              textAction={"Login"}
              onPress={() => setForm("login")}
            />
          }
        />
      </View>
    </Modal>
  );
};

const FORM_WIDTH = 480;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    formWrapper: {
      ...theme.presets.column,

      [Theme.media.up("small")]: {
        width: FORM_WIDTH,
      },

      [Theme.media.down("small")]: {
        width: "100%",
      },
    },
  }),
  true,
);
