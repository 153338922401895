import { React, variantProvider, Theme } from "@/app";
import { AppStatus, useAppSelector } from "@/redux";
import { onUpdate } from "@codeleap/common";
import { Icon, Overlay, View, ActivityIndicator } from "@/components";
import { Logo } from "./Logo";

const indicatorSize = 60;

export const AppStatusOverlay: React.FC = () => {
  const { status } = useAppSelector((store) => store.AppStatus);

  onUpdate(() => {
    if (status === "done") {
      setTimeout(() => {
        AppStatus.set("idle");
      }, 2000);
    }
  }, [status]);

  const visibilityStyle = (appStatus) => {
    const isStatusVisible = status === appStatus;
    return {
      transform: `scale(${isStatusVisible ? 1 : 0})`,
      transition: "transform 0.3s ease",
    };
  };

  const visibleOverlay = ["loading", "done"].includes(status);

  return (
    <>
      <Overlay visible={visibleOverlay} styles={{ wrapper: { zIndex: 199 } }} />
      <View css={[styles.wrapper]}>
        <View css={[styles.container, visibilityStyle("done")]}>
          <Icon
            name="check"
            size={Theme.values.iconSize[4]}
            color={Theme.colors.light.primary3}
            style={visibilityStyle("done")}
          />
        </View>

        <View css={[styles.container, visibilityStyle("loading")]}>
          <ActivityIndicator css={visibilityStyle("loading")} />
        </View>
      </View>
      <View
        css={[styles.splashWrapper, { opacity: status === "splash" ? 1 : 0 }]}
      >
        <Logo variants={["splash"]} responsive={false} />
      </View>
    </>
  );
};

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    container: {
      width: indicatorSize,
      height: indicatorSize,
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.borderRadius.rounded,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    wrapper: {
      ...theme.presets.absolute,
      ...theme.presets.whole,
      ...theme.presets.justifyCenter,
      ...theme.presets.alignCenter,
      zIndex: 200,
      pointerEvents: "none",
      transition: "transform 0.3s ease",
    },
    splashWrapper: {
      display: "flex",
      ...theme.presets.fixed,
      ...theme.presets.whole,
      ...theme.presets.justifyCenter,
      ...theme.presets.alignCenter,
      zIndex: 200,
      pointerEvents: "none",
      transition: "all 0.3s ease",
      backgroundColor: theme.colors.background,
    },
  }),
  true,
);
