import { Settings, Theme, variantProvider } from "@/app";
import { AppLogo, Icon, SignOut, Text, Touchable, View } from "@/components";
import { useBooleanToggle } from "@codeleap/common";
import { navigate } from "gatsby";
import { useAppSelector } from "@/redux";

const BREAKPOINT = "mid";
const Badge = ({ count }) => (
  <View style={styles.badge}>
    <Text style={{ color: "white", fontWeight: "bold" }}>{count}</Text>
  </View>
);

const Menu = ({ page, title, hasUnreadMessages, unreadMessagesCount }) => {
  return (
    <View
      style={styles.menu}
      variants={[
        "absolute",
        "column",
        "fullWidth",
        "bg:background",
        "paddingVertical:2",
        "gap:1",
      ]}
    >
      <View down={BREAKPOINT} variants={["column"]}>
        <Text text="Hello" variants={["p2", "color:neutral6"]} />
        <Text text={title} variants={["h4", "color:neutral9"]} />
      </View>
      <View variants={["column", "gap:1"]}>
        <Touchable
          onPress={() => navigate("/list/guests")}
          style={styles.button}
          variants={[
            "justifyStart",
            "paddingVertical:1",
            "paddingHorizontal:2",
            page === "guests" ? "bg:secondary1" : "",
            "border-radius:small",
          ]}
        >
          <Icon
            name={"users"}
            size={Theme.values.iconSize[2]}
            color={
              page === "guests"
                ? Theme.colors.light.neutral10
                : Theme.colors.light.neutral5
            }
          />
          <Text
            text="Guests"
            variants={[
              "marginLeft:2",
              page === "guests" ? "color:neutral10" : "",
            ]}
          />
        </Touchable>
        <Touchable
          onPress={() => navigate("/list/notifications")}
          style={styles.button}
          variants={[
            "justifyStart",
            "paddingVertical:1",
            "paddingHorizontal:2",
            "border-radius:small",
            page === "notifications" ? "bg:secondary1" : "",
          ]}
        >
          <Icon
            name={"bell"}
            size={Theme.values.iconSize[2]}
            color={
              page === "notifications"
                ? Theme.colors.light.neutral10
                : Theme.colors.light.neutral5
            }
          />
          <Text
            text="Notifications"
            variants={[
              "marginLeft:2",
              page === "notifications" ? "color:neutral10" : "",
            ]}
          />
        </Touchable>
        <Touchable
          onPress={() => navigate("/list/chat")}
          style={styles.button}
          variants={[
            "gap:1",
            "justifyStart",
            "paddingVertical:1",
            "paddingHorizontal:2",
            "border-radius:small",
            page === "chat" ? "bg:secondary1" : "",
          ]}
          debugName={"chat"}
        >
          <Icon
            name={"chat"}
            size={Theme.values.iconSize[3]}
            color={
              page === "chat" || page === "messages"
                ? Theme.colors.light.neutral10
                : Theme.colors.light.neutral5
            }
          />
          <Text
            text="Chat"
            variants={[
              "marginLeft:2",
              page === "chat" || page === "messages" ? "color:neutral10" : "",
              "bold",
            ]}
          />
          {hasUnreadMessages && <Badge count={unreadMessagesCount} />}
        </Touchable>
      </View>
    </View>
  );
};

export function Sidebar({
  page,
  hotel,
  hasUnreadMessages,
  unreadMessagesCount,
}) {
  const isDevelopment = useAppSelector((store) => store.AppStatus.isDev);
  const [menuIsVisible, setMenuIsVisible] = useBooleanToggle(false);
  return (
    <View variants={["column", "relative"]}>
      <View
        responsiveVariants={{ [BREAKPOINT]: ["marginBottom:0"] }}
        variants={["marginBottom:4", "alignCenter", "justifySpaceBetween"]}
      >
        <AppLogo />

        <View
          variants={["alignSelfStart", "gap:2", "hidden"]}
          responsiveVariants={{ [BREAKPOINT]: ["inlineFlex"] }}
        >
          <Touchable onPress={setMenuIsVisible}>
            <Icon
              name={menuIsVisible ? "x" : "menu"}
              size={Theme.values.iconSize[3]}
              color={Theme.colors.light.neutral5}
            />
          </Touchable>

          <SignOut />
        </View>
      </View>

      {menuIsVisible ? (
        <Menu
          title={hotel.title}
          page={page}
          hasUnreadMessages={hasUnreadMessages}
          unreadMessagesCount={unreadMessagesCount}
        />
      ) : null}

      <View
        variants={["column", "gap:1"]}
        responsiveVariants={{ [BREAKPOINT]: ["hidden"] }}
      >
        <Touchable
          onPress={() => navigate("/list/guests")}
          style={styles.button}
          variants={[
            "justifyStart",
            "paddingVertical:1",
            "paddingHorizontal:2",
            page === "guests" ? "bg:secondary1" : "",
            "border-radius:small",
          ]}
        >
          <Icon
            name={"users"}
            size={Theme.values.iconSize[2]}
            color={
              page === "guests"
                ? Theme.colors.light.neutral10
                : Theme.colors.light.neutral5
            }
          />
          <Text
            text="Guests"
            variants={[
              "marginLeft:2",
              page === "guests" ? "color:neutral10" : "",
            ]}
          />
        </Touchable>
        <Touchable
          onPress={() => navigate("/list/notifications")}
          style={styles.button}
          variants={[
            "justifyStart",
            "paddingVertical:1",
            "paddingHorizontal:2",
            "border-radius:small",
            page === "notifications" ? "bg:secondary1" : "",
          ]}
        >
          <Icon
            name={"bell"}
            size={Theme.values.iconSize[2]}
            color={
              page === "notifications"
                ? Theme.colors.light.neutral10
                : Theme.colors.light.neutral5
            }
          />
          <Text
            text="Notifications"
            variants={[
              "marginLeft:2",
              page === "notifications" ? "color:neutral10" : "",
            ]}
          />
        </Touchable>
        <Touchable
          onPress={() => navigate("/list/chat")}
          style={styles.button}
          variants={[
            "gap:1",
            "justifyStart",
            "paddingVertical:1",
            "paddingHorizontal:2",
            "border-radius:small",
            page === "chat" ? "bg:secondary1" : "",
          ]}
        >
          <Icon
            name={"chat"}
            size={Theme.values.iconSize[3]}
            color={
              page === "chat" || page === "messages"
                ? Theme.colors.light.neutral10
                : Theme.colors.light.neutral5
            }
          />
          <Text
            text="Chat"
            variants={[
              "marginLeft:2",
              page === "chat" || page === "messages" ? "color:neutral10" : "",
              "bold",
            ]}
          />
          {hasUnreadMessages && <Badge count={unreadMessagesCount} />}
        </Touchable>
      </View>
    </View>
  );
}

const MENU_TOP = 40;
const BUTTON_WIDTH = 288;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    button: {
      minWidth: BUTTON_WIDTH,
      width: "100%",
    },
    menu: {
      top: MENU_TOP,
      zIndex: 999,
    },
    badge: {
      backgroundColor: theme.colors.secondary4,
      borderRadius: 8,
      padding: 2,
      //width: 5,
      justifyContent: "center",
      alignItems: "center",
    },
  }),
  true,
);
