import { Widget } from "@/utils";
import { ReactStateProps, useState } from "@codeleap/common";
import { Text, Button, View } from "@/components";
import { React } from "@/app";

type TVariantsWidget = Widget<
  ReactStateProps<"value", string[]>,
  {
    multiple?: boolean;
    type?: "vertical" | "horizontal";
    options: string[];
    onValueChange?: () => void;
    defaultVariants?: string[];
  }
>;

export const VariantsWidget: TVariantsWidget = {
  Control({ value, setValue, label, args, onVariantsChange }) {
    function press(item: string, selected: boolean) {
      if (args.multiple) {
        const idx = value.findIndex((vl) => vl === item);

        if (idx >= 0) {
          setValue(value.filter((_, i) => i !== idx));
          return;
        }

        setValue([...value, item]);
      } else {
        if (selected) setValue([]);
        else setValue([item]);
      }

      args?.onValueChange?.();
      onVariantsChange?.();
    }

    const isTypeHorizontal = args.type === "horizontal";
    const orientation = isTypeHorizontal ? "row" : "column";

    return (
      <>
        <Text
          variants={["p2", "marginBottom:1", "color:neutral7"]}
          text={label}
        />

        <View variants={["justifyStart", orientation, "flex", "gap:1", "wrap"]}>
          {args.options.map((item, i) => {
            const selected = value.find((vl) => vl === item);

            return (
              <Button
                key={item}
                debugName={`${item} variant button`}
                onPress={() => press(item, !!selected)}
                variants={[selected && "primary:outline"]}
                text={item}
              />
            );
          })}
        </View>
      </>
    );
  },
  handle(params) {
    const [value, setValue] = useState(params?.defaultVariants ?? []);
    return {
      value,
      setValue,
    };
  },
};
