import { ViewComposition, ViewPresets } from "@codeleap/web";
import { variantProvider } from "../theme";

const createViewStyle = variantProvider.createVariantFactory<ViewComposition>();

export const AppViewStyles = {
  ...ViewPresets,
  default: createViewStyle((t) => ({
    wrapper: {
      display: "flex",
    },
  })),
  separator: createViewStyle((theme) => ({
    wrapper: {
      width: "100%",
      height: 2,
      backgroundColor: theme.colors.neutral5,

      [theme.media.down("mid")]: {
        height: 4,
      },
    },
  })),
  confirmed: createViewStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(1),
      borderRadius: theme.borderRadius.small,
      backgroundColor: theme.colors.positive1,
    },
  })),
  pending: createViewStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(1),
      borderRadius: theme.borderRadius.small,
      backgroundColor: theme.colors.destructive1,
    },
  })),
  current: createViewStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(1),
      borderRadius: theme.borderRadius.small,
      backgroundColor: theme.colors.info1,
    },
  })),
  past: createViewStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(1),
      borderRadius: theme.borderRadius.small,
      backgroundColor: theme.colors.neutral3,
    },
  })),
  guest: createViewStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(1),
      borderRadius: theme.borderRadius.small,
      backgroundColor: theme.colors.secondary1,
    },
  })),
  notification: createViewStyle((theme) => ({
    wrapper: {
      width: "100%",
      borderRadius: theme.borderRadius.small * 2,
      ...theme.border.neutral3({ width: theme.values.borderWidth.medium }),
      justifyContent: "flex-end",
    },
  })),
  "notification:hover": createViewStyle((theme) => ({
    wrapper: {
      width: "100%",
      borderRadius: theme.borderRadius.small * 2,
      ...theme.border.secondary4({ width: theme.values.borderWidth.medium }),
      justifyContent: "flex-end",
    },
  })),
};
