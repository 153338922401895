export const effects = {
  elevated: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
  },
  light: {
    boxShadow: "0px 8px 24px rgba(153, 153, 153, 0.2)",
  },
  heavy: {
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.35)",
  },
};
