import { IsDevEnv } from "@/app";
import { createForm, yup } from "@codeleap/common";
import { fakeProfile, testerOptions, testers } from "../testers";
export * from "../testers";

const initialState = IsDevEnv ? testers.default : undefined;

export const login = () =>
  createForm("login", {
    full_name: {
      type: "text",
      label: "Hotel Name",
      placeholder: "Hotel Name",
    },
    email: {
      type: "text",
      placeholder: "email@email.com",
      validate: yup.string().required().email(),

      defaultValue: initialState?.email,
    },
    password: {
      type: "text",
      password: true,
      validate: yup.string().required().min(6).max(128),

      placeholder: "••••••••••••",
      defaultValue: initialState?.password,
    },
    remember: {
      type: "checkbox",
      label: "Remember information",
      defaultValue: false,
    },
    tester: {
      type: "select",
      label: "Tester",
      options: testerOptions,
      defaultValue: "default",
    },
  });

export const signup = () => {
  const loginForm = login();

  return createForm("signup", {
    email: {
      ...loginForm.config.email,
      defaultValue: fakeProfile.email,
    },
    password: {
      ...loginForm.config.password,
      defaultValue: fakeProfile.password,
    },
    full_name: {
      ...loginForm.config.full_name,
      defaultValue: fakeProfile.first_name,
      validate: yup.string().required(),
    },
    role: {
      type: "text",
      label: "Role",
      defaultValue: "manager",
    },
    repeatPassword: {
      ...loginForm.config.password,
      label: "Confirm Password",
      defaultValue: fakeProfile.repeatPassword,
      validate: (repeatPassword, { password }) => {
        const isValid = repeatPassword === password;
        return {
          valid: isValid,
          message: isValid ? "" : "Passwords don't match",
        };
      },
    },
    tester: loginForm.config.tester,
  });
};

export const forgotPassword = () => {
  const loginForm = login();
  return createForm("forgotPassword", {
    email: loginForm.config.email,
    tester: loginForm.config.tester,
  });
};

export const editProfile = () => {
  const signupForm = signup();

  return createForm("editProfile", {
    email: signupForm.config.email,
    // first_name: signupForm.config.first_name,
    // last_name: signupForm.config.last_name,
    // avatar: signupForm.config.avatar,
  });
};
