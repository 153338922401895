import { Toaster, toast } from "react-hot-toast";
import { Avatar } from "@/components";

const CustomToast = ({ toastData, toastParams, onClick }) => (
  <div
    className={`${
      toastParams.visible ? "animate-enter" : "animate-leave"
    } custom-toast-container`}
    style={{
      maxWidth: "20rem",
      width: "100%",
      backgroundColor: "#ffffff",
      boxShadow:
        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      borderRadius: "0.375rem",
      padding: "1rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}
  >
    <div
      className="custom-toast-avatar"
      style={{ marginRight: "1rem" }}
      onClick={onClick}
    >
      <Avatar
        className="custom-toast-avatar-image"
        variants={["rounded"]}
        style={{
          width: "4rem",
          height: "4rem",
          borderRadius: "9999px",
        }}
        name={toastData.avatar}
      />
    </div>
    <div className="custom-toast-text" style={{ flex: "1" }}>
      <p
        className="custom-toast-name"
        style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "0.5rem" }}
      >
        {toastData.sender}
      </p>
      <p
        className="custom-toast-message"
        style={{ fontSize: "0.875rem", color: "#4B5563" }}
      >
        {toastData.message}
      </p>
    </div>
    <div className="custom-toast-actions">
      <button
        onClick={() => toast.dismiss(toastParams.id)}
        className="custom-toast-close"
        style={{
          border: "none",
          borderRadius: "0.375rem",
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: "bold",
          color: "#3B82F6",
          cursor: "pointer",
          backgroundColor: "#EFF6FF",
        }}
      >
        Close
      </button>
    </div>
  </div>
);

const newMessageToast = (
  toastData: ToastData,
  toastParams,
  position,
  onClick,
) => {
  toast.custom(
    <CustomToast
      toastData={toastData}
      toastParams={toastParams}
      onClick={onClick}
    />,
    { position: position, duration: 5000 },
  );
};
export interface ToastData {
  sender: string;
  message: string;
  avatar: string;
}

export { Toaster, newMessageToast };
