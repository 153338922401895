import { AppStatus } from "@/redux";
import { getFirebase, getFirebaseAuth } from "../../firebaseApp";
import { QueryKeys } from "./queryKeys";
import { useSession } from "./useSession";
import { ERRORS, isEmailAvailable, Profile } from "./views";

export function useSignup() {
  const { createProfile, warnEmailInUse } = useSession();

  const signup = async (data: Partial<Profile>) => {
    const firebaseAuth = await getFirebaseAuth();

    AppStatus.set("loading");
    try {
      const emailAvailable = await isEmailAvailable(data.email);
      logger.debug("Email available", emailAvailable, "Auth");
      let currentUser = firebaseAuth().currentUser;
      logger.debug("Firebase currentUser", currentUser, "Sign Up");

      currentUser?.reload?.();

      logger.debug(
        "Firebase currentUser (after reload)",
        currentUser,
        "Sign Up",
      );

      if (!emailAvailable) {
        warnEmailInUse();
        throw ERRORS.emailInUse;
        return;
      }

      try {
        logger.debug("Creating user with email and password", data, "Sign Up");
        const newUser = await firebaseAuth().createUserWithEmailAndPassword(
          data.email,
          data.password,
        );
        currentUser = newUser.user;

        currentUser?.reload?.();
      } catch (e) {
        logger.error("Error on signup", e, "Auth");

        if (e.code === "auth/email-already-in-use") {
          warnEmailInUse();
        }
      }
      logger.debug("Creating profile", data, "Sign Up");

      await createProfile.mutateAsync(data);
      await QueryKeys.me.refresh();

      AppStatus.set("done");

      return {
        user: currentUser,
      };
    } catch (e) {
      AppStatus.set("idle");
      logger.error("Error on signup", e, "Auth");
    }
  };

  return signup;
}
