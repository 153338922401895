import {
  AppCardBaseStyles,
  CardBaseComposition,
  CardBasePresets,
} from "@/app/stylesheets/CardBase";
import {
  ComponentVariants,
  StylesOf,
  TypeGuards,
  useDefaultComponentStyle,
} from "@codeleap/common";
import { TouchableProps } from "@codeleap/web";
import { PropsOf } from "@emotion/react";
import { Touchable, View } from "@/components";
import React from "react";

export type _CardBaseProps = ComponentVariants<typeof AppCardBaseStyles> &
  Omit<PropsOf<typeof View>, "styles" | "variants"> &
  Omit<PropsOf<typeof Touchable>, "styles" | "variants"> & {
    styles?: StylesOf<CardBaseComposition>;
    style?: any;
    debugName?: string;
    children?: React.ReactNode;

    innerWrapperProps?: PropsOf<typeof View>;
    onPress?: TouchableProps["onPress"];
    gap?: number;
  };

export type CardBaseProps = _CardBaseProps & {
  WrapperComponent?: (props: _CardBaseProps) => React.ReactElement;
  InnerWrapperComponent?: (props: _CardBaseProps) => React.ReactElement;
};

export const CardBase = (props: CardBaseProps) => {
  const {
    variants = [],
    responsiveVariants = {},
    styles = {},
    style = {},
    children,
    onPress = null,
    WrapperComponent = null,
    InnerWrapperComponent = null,
    innerWrapperProps = {},
    gap = 1,
    ...rest
  } = props;

  const variantStyles = useDefaultComponentStyle<
    "u:CardBase",
    typeof CardBasePresets
  >("u:CardBase", {
    variants,
    responsiveVariants,
    styles,
  });

  const isPressable = TypeGuards.isFunction(onPress);

  const wrapperStyles = React.useMemo(
    () => [
      variantStyles.wrapper,
      style,
      isPressable && variantStyles["wrapper:pressable"],
    ],
    [],
  );

  const Wrapper = TypeGuards.isNil(WrapperComponent)
    ? isPressable
      ? Touchable
      : View
    : WrapperComponent;
  const InnerWrapper = TypeGuards.isNil(InnerWrapperComponent)
    ? View
    : InnerWrapperComponent;

  const pressableProps = isPressable
    ? {
        onPress,
      }
    : {};

  return (
    <Wrapper {...rest} {...pressableProps} css={wrapperStyles}>
      <InnerWrapper
        // @ts-expect-error We've reached the limits
        variants={[`gap:${gap}`]}
        {...innerWrapperProps}
        css={[
          variantStyles.innerWrapper,
          isPressable && variantStyles["innerWrapper:pressable"],
        ]}
      >
        {children}
      </InnerWrapper>
    </Wrapper>
  );
};
