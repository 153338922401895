import { AppStatus } from "@/redux";
import { getFirebase } from "../../firebaseApp";
import { QueryKeys } from "./queryKeys";
import { ERRORS, isEmailAvailable, Profile } from "./views";

export const codeLoginErrors = {
  ["auth/wrong-password"]: "Email or password is incorrect",
};

export function useLogin() {
  const login = async (data: Partial<Profile>) => {
    AppStatus.set("loading");
    try {
      const { email, password } = data;

      const emailAvailable = await isEmailAvailable(email);

      if (emailAvailable) {
        alert("Could not find an account matching the specified email address");
        throw ERRORS.notRegistered;
      }
      const firebase = await getFirebase();
      await firebase
        .auth()
        .signInWithEmailAndPassword(email.trim(), password.trim());

      await QueryKeys.me.refresh();
      AppStatus.set("splash");
      AppStatus.authFinished();
    } catch (e) {
      AppStatus.set("idle");

      const errorMessage = codeLoginErrors?.[e?.code];

      if (!!errorMessage) {
        alert(errorMessage);
      }

      logger.error("Error on login", e, "Auth");
    }
  };

  return login;
}
