import { View, Alert, Text, TextInput, Status } from "@/components";
import {
  AnyFunction,
  onMount,
  onUpdate,
  useBooleanToggle,
  useForm,
  useRef,
  useState,
} from "@codeleap/common";
import {
  Button,
  Checkbox,
  FileInput,
  FileInputRef,
  Icon,
  SearchInput,
} from "@codeleap/web";
import { AppForms, Theme, variantProvider } from "@/app";
import { APIClient } from "@/services";
import { useDropzone } from "react-dropzone";
import { AppStatus } from "@/redux";

type ActionModalProps = {
  toggle: AnyFunction;
  visible: boolean;
  debugName: string;
  isUpdate?: boolean;
  defaultValues?: {
    id: string;
    title: string;
    description: string;
    url: string;
    image: string;
    url_name: string;
  };
};

const Page1 = ({ setImage, image, form }) => {
  const fileInputRef = useRef<FileInputRef>(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  function onDrop(image) {
    setImage(image[0]);
  }

  return (
    <View variants={["column"]}>
      <TextInput
        {...form.register("title")}
        label={
          <View variants={["marginBottom:1"]}>
            <Text text="Title" variants={["p2", "color:neutral5", "bold"]} />
            <Text text="*" variants={["p2", "bold", "color:alert2"]} />
          </View>
        }
        variants={["create:notification"]}
      />
      <TextInput
        {...form.register("description")}
        label={
          <View variants={["marginBottom:1"]}>
            <Text
              text="Description"
              variants={["p2", "color:neutral5", "bold"]}
            />
            <Text text="*" variants={["p2", "color:alert2", "bold"]} />
          </View>
        }
        multiline
        variants={["create:notification"]}
      />
      <TextInput
        {...form.register("url")}
        label={
          <View variants={["marginBottom:1"]}>
            <Text text="URL" variants={["p2", "color:neutral5", "bold"]} />
          </View>
        }
        variants={["create:notification"]}
      />
      <TextInput
        {...form.register("url_name")}
        label={
          <View variants={["marginBottom:1"]}>
            <Text text="URL Name" variants={["p2", "color:neutral5", "bold"]} />
          </View>
        }
        variants={["create:notification"]}
      />
      <View variants={["column", "gap:1"]}>
        <Text text="Image" variants={["p2", "color:neutral5", "bold"]} />
        <View variants={isDragActive ? "notification:hover" : "notification"}>
          <View style={styles.content} {...getRootProps()} variants={["gap:1"]}>
            <Icon name="image" size={20} color={Theme.colors.light.neutral4} />
            {image || form.values?.image ? (
              <Text
                variants={["color:neutral6"]}
                text={form.values.image ? "Change image" : image?.name}
              />
            ) : isDragActive ? (
              <Text variants={["color:neutral4"]} text="Drop image here" />
            ) : (
              <Text variants={["color:neutral4"]} text="Drag your image here" />
            )}
          </View>

          <FileInput
            {...getInputProps()}
            ref={fileInputRef}
            onFileSelect={onDrop}
          />

          <Button
            debugName="Image Input"
            text="Select"
            variants={["file:image"]}
            onPress={() => fileInputRef.current.openFilePicker()}
          />
        </View>
      </View>
    </View>
  );
};

const Item = ({
  reservation,
  allSelected,
  selectUser,
  setAllSelected,
  selectedUsers,
}) => {
  const [isSelected, setIsSelected] = useBooleanToggle(true);

  onUpdate(() => {
    if (selectedUsers.length === 0 || allSelected) setIsSelected(allSelected);
  }, [allSelected]);

  onUpdate(() => {
    if (isSelected) {
      selectUser((oldState) => [...oldState, reservation.profile.id]);
    } else {
      setAllSelected(false);
      selectUser((oldState) =>
        oldState.filter(
          (selectedReservation) =>
            selectedReservation !== reservation.profile.id,
        ),
      );
    }
  }, [isSelected]);

  return (
    <Checkbox
      onValueChange={setIsSelected}
      value={isSelected}
      debugName={"Guest Notification"}
      variants={["left", "create:notification"]}
      label={
        <View
          style={styles.label}
          variants={["paddingHorizontal:1", "justifySpaceBetween"]}
        >
          <Text
            text={reservation.profile.full_name}
            variants={["p2", "color:neutral6"]}
          />
          <Status status={reservation.status} />
        </View>
      }
    />
  );
};

const Page2 = ({ form, reservations, search }) => {
  const availableReservations = reservations?.filter(
    (reservation) =>
      reservation.status === "Current" || reservation.status === "Confirmed",
  );
  const [allSelected, setAllSelected] = useBooleanToggle(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const allGuestsLength = availableReservations.length;

  onUpdate(() => {
    if (selectedUsers.length === allGuestsLength) setAllSelected(true);
    form.setFieldValue("profile", selectedUsers);
  }, [selectedUsers]);

  onUpdate(() => {
    form.setFieldValue("all_guests", allSelected);
  }, [allSelected]);

  return (
    <View variants={["column"]}>
      <SearchInput
        label={
          <View>
            <Text
              text="Guests"
              variants={["p2", "color:neutral5", "bold", "marginBottom:1"]}
            />
          </View>
        }
        placeholder="Search guests"
        onSearchChange={search}
        variants={["create:notification"]}
      />
      <View style={styles.list} variants={["column", "gap:1"]}>
        <Checkbox
          onValueChange={(value) => {
            if (!value) setSelectedUsers([]);
            setAllSelected(value);
          }}
          value={allSelected}
          debugName={"Guest Notification"}
          variants={["left", "create:notification"]}
          label={
            <View
              style={styles.label}
              variants={["paddingHorizontal:1", "justifySpaceBetween"]}
            >
              <Text text="All guests" variants={["p2", "color:neutral6"]} />
            </View>
          }
        />
        {availableReservations.map((reservation) => (
          <Item
            reservation={reservation}
            allSelected={allSelected}
            selectUser={setSelectedUsers}
            setAllSelected={setAllSelected}
            selectedUsers={selectedUsers}
          />
        ))}
      </View>
    </View>
  );
};

export function CreateNotificationModal(props: ActionModalProps) {
  const { toggle, visible, debugName, defaultValues, isUpdate = false } = props;
  const [search, setSearch] = useState("");
  const reservations = APIClient.Hotel.reservationsManager.use({
    filter: {
      q: search,
    },
  });
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [page, setPage] = useState(1);

  const announcements = APIClient.Hotel.announcementsManager.use();

  const form = useForm(AppForms.createAnnouncement, {});

  onMount(() => {
    form.setFormValues({
      description: defaultValues?.description,
      title: defaultValues?.title,
      image: defaultValues?.image,
      url: defaultValues?.url,
      url_name: defaultValues?.url_name,
    });
  });

  const onAction = async () => {
    if (page === 1) {
      setPage(2);

      return;
    }

    toggle();

    AppStatus.set("loading");

    const formUrl = form.values?.url;

    const url =
      ["http"].some((start) => formUrl?.startsWith(start)) || !formUrl
        ? formUrl
        : `https://${formUrl}`;

    try {
      isUpdate
        ? await announcements.update({
            ...form.values,
            image: selectedImage,
            id: defaultValues.id,
            url,
          })
        : await announcements.create({
            ...form.values,
            image: selectedImage,
            url,
          });

      form.reset();
      setSelectedImage(null);

      setPage(1);
    } catch (err) {
      logger.error("ERROR", err);
    }

    AppStatus.set("idle");
  };

  const { title, description } = form.values;

  return (
    <Alert
      title={"Create announcement"}
      type={"warn"}
      onAction={onAction}
      debugName={debugName}
      visible={visible}
      isRow
      isAction={true}
      showClose={true}
      dismissOnBackdrop={false}
      closeOnAction={false}
      closeOnDismiss={false}
      style={{
        borderRadius: Theme.spacing.value(4),
        padding: Theme.spacing.value(4),
      }}
      onDismiss={() => setPage(1)}
      actionButtonProps={{
        debugName: "Create notification - Next page",
        text: page === 1 ? "Next" : "Send",
        variants: ["action", "fullWidth"],
        disabled: !(title && description),
      }}
      dismissButtonProps={{
        debugName: "Back to page 1",
        text: "Back",
        variants: ["dismiss"],
      }}
      toggle={toggle}
      showDismissButton={page !== 1}
    >
      {page === 1 ? (
        <Page1 form={form} image={selectedImage} setImage={setSelectedImage} />
      ) : (
        <Page2
          form={form}
          reservations={reservations.items}
          search={setSearch}
        />
      )}
    </Alert>
  );
}

const LIST_HEIGHT = 252;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    list: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: LIST_HEIGHT,
      overflow: "auto",
    },
    label: {
      width: "100%",
      alignItems: "center",
    },
    content: {
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
      ...theme.spacing.paddingHorizontal(1),
      ...theme.spacing.paddingVertical(1.5),
    },
    input: {
      width: "100%",
      borderRadius: theme.borderRadius.small,
      ...theme.border.neutral3({ width: theme.values.borderWidth.medium }),
      justifyContent: "flex-end",
    },
  }),
  true,
);
