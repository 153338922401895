import { useGlobalAlertComponent } from "@codeleap/web";
import { Alert } from "../Modals";

export const OSAlert = (props) => {
  const { visible, toggle } = useGlobalAlertComponent(props);

  const onDismiss = () => {
    props.args?.onDismiss?.();
    toggle();
  };

  const onAction = () => {
    toggle();
    props?.args?.onAction?.();
  };

  return (
    <Alert
      {...props.args}
      visible={visible}
      onAction={onAction}
      closeOnDismiss
      onClose={onDismiss}
      onDismiss={onDismiss}
    />
  );
};
