import { React } from "@/app";
import { AvatarStyles } from "../app/stylesheets/Avatar";
import {
  AvatarComposition,
  ComponentVariants,
  matchInitialToColor,
  PropsOf,
  StylesOf,
  TypeGuards,
  useDefaultComponentStyle,
  useMemo,
  WebInputFile,
  IconPlaceholder,
  getNestedStylesByKey,
} from "@codeleap/common";
import { FileInput, Image, View, Text, Touchable, Icon } from ".";
import { Badge, BadgeComponentProps, useFileInput } from "@codeleap/web";
import { APIClient } from "@/services";

type ImageProps = PropsOf<typeof Image>;

type AvatarProps = Omit<PropsOf<typeof Touchable>, "styles" | "variants"> &
  BadgeComponentProps & {
    styles?: StylesOf<AvatarComposition>;
    onChange?: (picture: WebInputFile) => void;
    image?: ImageProps["source"];
    badge?: boolean;
    name?: string | string[];
    debugName: string;
    firstNameOnly?: boolean;
    text?: string;
    description?: string;
    icon?: IconPlaceholder;
    badgeIcon?: IconPlaceholder;
  } & ComponentVariants<typeof AvatarStyles>;

export const Avatar = (props: AvatarProps) => {
  const { profile } = APIClient.Session.useSession();

  const {
    debugName,
    name = [profile?.first_name, profile?.last_name],
    firstNameOnly = true,
    image = profile?.avatar,
    responsiveVariants = {},
    variants = [],
    styles = {},
    style = {},
    icon,
    badgeIcon,
    onChange,
    onPress,
    text = "",
    description,
    badge = false,
    badgeProps = {},
    ...viewProps
  } = props as AvatarProps;

  const input = useFileInput();

  const editable = !!onChange;
  const hasImage = !!image;

  const handlePress = async () => {
    if (!editable) {
      onPress?.();
    } else {
      const files = await input.openFilePicker();

      if (!files.length) return;

      onChange?.(files[0]);
    }
  };

  const variantStyles = useDefaultComponentStyle<
    "u:Avatar",
    typeof AvatarStyles
  >("u:Avatar", {
    responsiveVariants,
    variants,
    styles,
  });

  const { initials, randomColor } = useMemo(() => {
    const [first = "", last = ""] = TypeGuards.isString(name)
      ? name.split(" ")
      : name;
    const initials = [first[0]];
    if (!firstNameOnly) {
      initials.push(last[0]);
    }
    return {
      initials: initials.join(" "),
      randomColor: matchInitialToColor(first[0]),
    };
  }, [name, firstNameOnly]);

  const renderContent = () => {
    if (hasImage) return <Image source={image} style={variantStyles.image} />;
    if (icon) return <Icon name={icon} style={variantStyles.icon} />;
    return <Text text={text || initials} style={variantStyles.initials} />;
  };

  const hasBackgroundColor = !!variantStyles?.touchable?.backgroundColor;

  const badgeStyles = getNestedStylesByKey("badge", variantStyles);

  return (
    <View css={[variantStyles.wrapper, style]}>
      <FileInput ref={input.ref} />
      <Touchable
        debugName={"Avatar " + debugName}
        onPress={handlePress}
        css={[
          variantStyles.touchable,
          !hasBackgroundColor && {
            backgroundColor: randomColor,
          },
        ]}
        {...viewProps}
      >
        {renderContent()}
        {!!description ? (
          <View style={variantStyles.descriptionOverlay}>
            <Text text={description} style={variantStyles.description} />
          </View>
        ) : null}
        <Badge badge={badge} styles={badgeStyles} {...badgeProps} />
      </Touchable>
      {badgeIcon && (
        <Touchable
          debugName={`${debugName} badge icon`}
          style={variantStyles.badgeIconWrapper}
          onPress={onPress}
        >
          <Icon name={badgeIcon} style={variantStyles.badgeIcon} />
        </Touchable>
      )}
    </View>
  );
};
