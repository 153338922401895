import { View, Page, Image } from "@/components";
import { React, variantProvider, AppImages, Theme } from "@/app";
import { useMediaQuery } from "@codeleap/web";

const BREAKPOINT = "mid";

export const AuthWrapper = ({ title, children }) => {
  const mediaQuery = Theme.media.down(BREAKPOINT);
  const isMobile = useMediaQuery(mediaQuery, {
    getInitialValueInEffect: false,
  });

  return (
    <Page centerContent={false} pageTitle={title}>
      <View style={styles.wrapper}>
        <View style={styles.leftContainer}>
          <Image
            source={isMobile ? AppImages.HomeImage : AppImages.SignUp}
            style={styles.image}
            objectFit="cover"
          />
        </View>

        <View style={styles.rightContainer}>{children}</View>
      </View>
    </Page>
  );
};

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      minHeight: "90vh",
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      overflow: "hidden",

      [theme.media.down(BREAKPOINT)]: {
        flexDirection: "column-reverse",
      },
    },
    leftContainer: {
      ...theme.presets.flex,
      ...theme.presets.alignCenter,
      backgroundColor: theme.colors.primary1,
    },
    rightContainer: {
      width: "45vw",
      ...theme.spacing.padding(4),
      ...theme.presets.center,

      [theme.media.down(BREAKPOINT)]: {
        width: "100vw",
        maxWidth: "100vw",
        ...theme.spacing.padding(2),
      },
    },
    image: {
      width: "45%",

      [theme.media.down(BREAKPOINT)]: {
        width: "100%",
      },
    },
  }),
  true,
);
