import { variantProvider, Theme, assignTextStyle, React } from "@/app";
import {
  View,
  Button,
  CenterWrapper,
  Drawer,
  Logo,
  Link,
  Avatar,
  ActionIcon,
  Text,
  Navigation,
  useNavigation,
  AuthModal,
} from "@/components";
import { onUpdate, useState } from "@codeleap/common";
import { AppStatus } from "@/redux";
import { navigate } from "gatsby";
import { APIClient } from "@/services";
import { useMediaQuery } from "@codeleap/web";

const navItems = [
  {
    name: "Components",
    url: "components/",
  },
  {
    name: "Auth Screens",
    url: "auth/screens/",
  },
  {
    name: "CRUD Example",
    url: "crudexample/",
  },
  {
    name: "About",
    url: "about/",
  },
];

const BREAKPOINT = "mid";

const NavContent = ({ isMobile }) => {
  const { isLoggedIn, profile } = APIClient.Session.useSession();
  const { matchPath } = useNavigation();

  return (
    <View
      variants={["gap:4", "center"]}
      responsiveVariants={{
        [BREAKPOINT]: ["column", "gap:2", "alignStart", "paddingVertical:3"],
      }}
    >
      {!!isLoggedIn && isMobile && (
        <View down={BREAKPOINT} css={styles.profileWrapper}>
          <Avatar
            variants={["medium"]}
            debugName={"Header Avatar"}
            onPress={() => {
              navigate("/profile/edit");
            }}
          />

          <View css={styles.profileInfos}>
            <Text variants={["h3"]} text={profile?.first_name} />
            <Text variants={["p3"]} text={profile?.email} css={styles.email} />
          </View>
        </View>
      )}

      {navItems.map((i) => {
        const isSelected = matchPath(i.url);

        return (
          <Link
            key={i.url}
            text={i.name}
            to={"/" + i.url}
            variants={["noUnderline"]}
            css={[styles.navItem, isSelected ? styles["navItem:selected"] : {}]}
          />
        );
      })}

      {!isLoggedIn && (
        <Button
          text={"Sign In"}
          variants={["circle", "small"]}
          responsiveVariants={{ [BREAKPOINT]: ["fullWidth", "large"] }}
          onPress={() =>
            isMobile ? navigate("/auth/signup") : AppStatus.setModal("auth")
          }
          debugName="header signIn"
        />
      )}

      {!!isLoggedIn && !isMobile && (
        <Avatar
          variants={["small"]}
          debugName={"Header Avatar"}
          onPress={() => {
            navigate("/profile/edit");
          }}
        />
      )}
    </View>
  );
};

const DrawerMenu = ({ isMobile }) => {
  const [drawerOpen, setDrawer] = useState(false);

  const toggleDrawer = (to = !drawerOpen) => {
    if (to) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    setDrawer(to);
  };

  onUpdate(() => {
    if (!isMobile && drawerOpen) {
      toggleDrawer(false);
    }
  }, [drawerOpen, isMobile]);

  return (
    <>
      <Drawer
        styles={{
          box: styles.drawer,
        }}
        css={[styles.drawer]}
        open={drawerOpen}
        size="75vw"
        position="right"
        toggle={toggleDrawer}
        showCloseButton
      >
        <NavContent isMobile={isMobile} />
      </Drawer>

      <ActionIcon
        icon="menu"
        variants={["marginLeft:auto", "minimal", "neutral10"]}
        onPress={toggleDrawer}
      />
    </>
  );
};

export const Header = () => {
  const mediaQuery = Theme.media.down(BREAKPOINT);
  const isMobile = useMediaQuery(mediaQuery, {
    getInitialValueInEffect: false,
  });

  return (
    <>
      <Navigation.Container>
        <CenterWrapper
          styles={{
            innerWrapper: styles.wrapper,
            wrapper: styles.floatingHeader as any,
          }}
          variants={["paddingVertical:2"]}
        >
          <Link to={"/"} css={styles.logoWrapper}>
            <Logo style={styles.logo} />
          </Link>

          {isMobile ? (
            <DrawerMenu isMobile={isMobile} />
          ) : (
            <View variants={["alignCenter"]}>
              <NavContent isMobile={isMobile} />
            </View>
          )}
        </CenterWrapper>
      </Navigation.Container>

      <AuthModal />
    </>
  );
};

const closeIconSize = 24;
const logoSize = 32;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
    },
    floatingHeader: {
      position: "sticky",
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.colors.background + "55",
      backdropFilter: "blur(4px)",
    },
    logo: {
      width: logoSize * 4,
      [Theme.media.down(BREAKPOINT)]: {
        width: logoSize,
      },
    },
    logoWrapper: {
      marginRight: "auto",

      [Theme.media.down(BREAKPOINT)]: {
        marginRight: theme.spacing.value(0),
        display: "flex",
        ...theme.presets.justifyCenter,
      },
    },
    drawer: {
      ...theme.presets.column,
      ...theme.presets.alignCenter,
      gap: theme.spacing.value(2),
      backgroundColor: theme.colors.background,
      alignItems: "stretch",
    },
    close: {
      backgroundColor: theme.colors.transparent,
      color: theme.colors.neutral9,
      width: closeIconSize,
      height: closeIconSize,
    },
    closeWrapper: {
      width: closeIconSize,
      marginLeft: "auto",
      ...theme.spacing.marginBottom(2),
    },
    profileWrapper: {
      borderRadius: theme.borderRadius.small,
      ...theme.border.neutral5({ width: 1 }),
      ...theme.presets.centerRow,
      ...theme.spacing.padding(2),
      ...theme.presets.fullWidth,
      ...theme.spacing.gap(2),
      ...theme.spacing.marginBottom(1),
    },
    profileInfos: {
      width: `calc(100% - ${theme.values.itemHeight.small + theme.spacing.value(3)}px)`,
      ...theme.presets.column,
      ...theme.presets.justifySpaceBetween,
      ...theme.spacing.gap(0.5),
    },
    email: {
      color: theme.colors.neutral8,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    navItem: {
      ...assignTextStyle("h4")(theme).text,
      ...theme.presets.textCenter,
      color: theme.colors.neutral9,
      fontWeight: "600",
      textDecoration: "none",

      [Theme.media.down(BREAKPOINT)]: {
        width: "100%",
        ...assignTextStyle("p1")(theme).text,
        ...theme.presets.textLeft,
        ...theme.spacing.padding(2),
        backgroundColor: theme.colors.transparent,
        borderRadius: theme.borderRadius.small,
        color: theme.colors.neutral10,
        fontWeight: "400",
      },
    },
    "navItem:selected": {
      color: theme.colors.primary3,

      [Theme.media.down(BREAKPOINT)]: {
        backgroundColor: theme.colors.primary1,
        color: theme.colors.primary3,
        fontWeight: "600",
      },
    },
  }),
  true,
);
