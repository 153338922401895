import React from "react";
import { useBooleanToggle, useI18N } from "@codeleap/common";
import { View, Link, Button, Modal } from "@/components";
import { I18NDictionary } from "@/app/i18n";

const languageOptions = Object.entries(I18NDictionary).map(([key, value]) => ({
  label: value.__LABEL,
  value: key,
}));

type LanguageSelectorProps = {
  path?: string;
};

export const LanguageSelector = ({ path = "/" }: LanguageSelectorProps) => {
  const [visible, toggle] = useBooleanToggle(false);
  const { locale, t } = useI18N();

  return (
    <>
      <View variants={["column", "gap:2"]}>
        <Button
          text={t("languageSelector", { language: locale })}
          onPress={toggle}
          debugName="open language selector modal"
          variants={["select"]}
          rightIcon="languages"
        />
      </View>

      <Modal
        toggle={toggle}
        debugName="Language selector"
        variants={["centered"]}
        visible={visible}
        title="Language"
        description="Select a language"
        withScrollContainer
      >
        <View variants={["gap:1", "column"]} style={{ minWidth: 300 }}>
          {languageOptions.map((item) => {
            const selected = item.value === locale;
            return (
              <Button
                component={Link}
                debugName="language selector"
                to={path}
                language={item.value}
                key={item.value}
                type="i18n"
                variants={["link", selected && "link:outline"]}
              >
                {item.label}
              </Button>
            );
          })}
        </View>
      </Modal>
    </>
  );
};
