import "./services/firebaseApp";

import { StyleProvider, ReactQuery, I18NProvider } from "@codeleap/common";
import { Settings, variantProvider, React, I18N } from "./app";
import { AppStatusOverlay, GlobalStyle, variants } from "./components";
import { Provider } from "react-redux";
import { store } from "./redux";
import { APIClient } from "./services";
import { Overlays } from "./pages";

const App = ({ children }) => {
  APIClient.Session.useSession(true);

  return (
    <>
      <GlobalStyle />
      {children}
      <AppStatusOverlay />
      <Overlays />
    </>
  );
};

export const Root = ({ children }) => {
  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <Provider store={store}>
        <StyleProvider
          settings={Settings}
          variantProvider={variantProvider}
          variants={variants}
          logger={logger}
        >
          <I18NProvider i18n={I18N}>
            <App>{children}</App>
          </I18NProvider>
        </StyleProvider>
      </Provider>
    </ReactQuery.QueryClientProvider>
  );
};
