import { Text, View } from "@/components";

type StatusProps = {
  status: "pending" | "confirmed" | "current" | "past";
};

export function Status(props: StatusProps) {
  const { status } = props;

  return (
    <View
      variants={[
        status?.toLocaleLowerCase() as
          | "pending"
          | "confirmed"
          | "current"
          | "past",
      ]}
    >
      <Text variants={["capitalize", "p3", "color:neutral6"]} text={status} />
    </View>
  );
}
