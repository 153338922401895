import { createSettings } from "@codeleap/common";
import Obfuscate from "./Obfuscate";
import * as Sentry from "@sentry/gatsby";

export const IsDevEnv = process.env.NODE_ENV === "development";

const enableSentry = !IsDevEnv;

const ENV = IsDevEnv ? "development" : "production";

const _Settings = createSettings({
  AppName: "BellHopp",
  CompanyName: "BellHopp Ltd.",
  Description: "This is BellHopp Web App",
  Environment: {
    IsDev: IsDevEnv,
    Type: ENV,
    InitTime: new Date(),
  },
  Application: {
    IsBrowser: false,
  },
  PerformanceInspector: {
    enable: true,
    maxRenders: 30,
  },
  Fetch: {
    ProductionURL: "https://prod.bellhopp.co.uk/",
    DevelopmentURL: "https://dev.bellhopp.co.uk/",
    ViewHTMLErrors: true,
  },
  Social: {
    FaceURL: "",
    LinkedinURL: "",
    instagramURL: "",
  },
  ContactINFO: {
    Website: "https://bellhopp.co.uk/",
    TermsAndPrivacy: "https://admin.bellhopp.co.uk/policies/",
    SupportEMAIL: "",
    ContactEMAIL: "",
    ContactPHONE: "",
  },
  Logger: {
    Level: "debug",
    DeviceIdentifier: "",
    StringifyObjects: true,
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
      "new NativeEventEmitter",
      "User cancelled",
      "React does not recognize the",
      "Unknown event handler property",
      "forwardRef render functions accept exactly ",
    ],
    Obfuscate,
    ComponentLogging: true,
  },
  Sentry: {
    enable: enableSentry,
    provider: Sentry,
    initArgs: {
      enabled: enableSentry,
      environment: ENV,
    },
    dsn: "",
  },
  Slack: {
    echo: {
      icon: "",
      token: "",
      channel: "",
    },
  },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId:
        "268760770384-ob7jfjntuorgqaj2tt2mvnqdokd634et.apps.googleusercontent.com",
    },
    AppleSignIn: {
      ServiceClientId: "",
      RedirectURI: "",
    },
    FacebookSDK: {
      AppId: "",
    },
  },
});

if (_Settings.Environment.IsDev) {
  // TODO read environment
  // _Settings = deepMerge(_Settings, env)
}

if (!_Settings.ContactINFO.TermsAndPrivacy) {
  _Settings.ContactINFO.TermsAndPrivacy = `https://admin.bellhopp.co.uk/policies/`;
}

export const Settings = _Settings;
