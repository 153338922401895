exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-auth-forgot-tsx": () => import("./../../../src/pages/auth/forgot.tsx" /* webpackChunkName: "component---src-pages-auth-forgot-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-screens-tsx": () => import("./../../../src/pages/auth/screens.tsx" /* webpackChunkName: "component---src-pages-auth-screens-tsx" */),
  "component---src-pages-auth-signup-tsx": () => import("./../../../src/pages/auth/signup.tsx" /* webpackChunkName: "component---src-pages-auth-signup-tsx" */),
  "component---src-pages-auth-wait-approval-tsx": () => import("./../../../src/pages/auth/wait-approval.tsx" /* webpackChunkName: "component---src-pages-auth-wait-approval-tsx" */),
  "component---src-pages-components-action-icon-tsx": () => import("./../../../src/pages/components/ActionIcon.tsx" /* webpackChunkName: "component---src-pages-components-action-icon-tsx" */),
  "component---src-pages-components-activity-indicator-tsx": () => import("./../../../src/pages/components/ActivityIndicator.tsx" /* webpackChunkName: "component---src-pages-components-activity-indicator-tsx" */),
  "component---src-pages-components-alert-tsx": () => import("./../../../src/pages/components/Alert.tsx" /* webpackChunkName: "component---src-pages-components-alert-tsx" */),
  "component---src-pages-components-app-status-tsx": () => import("./../../../src/pages/components/AppStatus.tsx" /* webpackChunkName: "component---src-pages-components-app-status-tsx" */),
  "component---src-pages-components-avatar-tsx": () => import("./../../../src/pages/components/Avatar.tsx" /* webpackChunkName: "component---src-pages-components-avatar-tsx" */),
  "component---src-pages-components-button-tsx": () => import("./../../../src/pages/components/Button.tsx" /* webpackChunkName: "component---src-pages-components-button-tsx" */),
  "component---src-pages-components-card-tsx": () => import("./../../../src/pages/components/Card.tsx" /* webpackChunkName: "component---src-pages-components-card-tsx" */),
  "component---src-pages-components-checkbox-tsx": () => import("./../../../src/pages/components/Checkbox.tsx" /* webpackChunkName: "component---src-pages-components-checkbox-tsx" */),
  "component---src-pages-components-debug-tsx": () => import("./../../../src/pages/components/Debug.tsx" /* webpackChunkName: "component---src-pages-components-debug-tsx" */),
  "component---src-pages-components-file-input-tsx": () => import("./../../../src/pages/components/FileInput.tsx" /* webpackChunkName: "component---src-pages-components-file-input-tsx" */),
  "component---src-pages-components-grid-tsx": () => import("./../../../src/pages/components/Grid.tsx" /* webpackChunkName: "component---src-pages-components-grid-tsx" */),
  "component---src-pages-components-icons-tsx": () => import("./../../../src/pages/components/Icons.tsx" /* webpackChunkName: "component---src-pages-components-icons-tsx" */),
  "component---src-pages-components-image-tsx": () => import("./../../../src/pages/components/Image.tsx" /* webpackChunkName: "component---src-pages-components-image-tsx" */),
  "component---src-pages-components-index-tsx": () => import("./../../../src/pages/components/index.tsx" /* webpackChunkName: "component---src-pages-components-index-tsx" */),
  "component---src-pages-components-language-selector-tsx": () => import("./../../../src/pages/components/LanguageSelector.tsx" /* webpackChunkName: "component---src-pages-components-language-selector-tsx" */),
  "component---src-pages-components-list-tsx": () => import("./../../../src/pages/components/List.tsx" /* webpackChunkName: "component---src-pages-components-list-tsx" */),
  "component---src-pages-components-modal-tsx": () => import("./../../../src/pages/components/Modal.tsx" /* webpackChunkName: "component---src-pages-components-modal-tsx" */),
  "component---src-pages-components-navigation-index-tsx": () => import("./../../../src/pages/components/Navigation/index.tsx" /* webpackChunkName: "component---src-pages-components-navigation-index-tsx" */),
  "component---src-pages-components-navigation-page-1-tsx": () => import("./../../../src/pages/components/Navigation/page-1.tsx" /* webpackChunkName: "component---src-pages-components-navigation-page-1-tsx" */),
  "component---src-pages-components-navigation-page-2-tsx": () => import("./../../../src/pages/components/Navigation/page-2/[...].tsx" /* webpackChunkName: "component---src-pages-components-navigation-page-2-tsx" */),
  "component---src-pages-components-number-increment-tsx": () => import("./../../../src/pages/components/NumberIncrement.tsx" /* webpackChunkName: "component---src-pages-components-number-increment-tsx" */),
  "component---src-pages-components-overlay-tsx": () => import("./../../../src/pages/components/Overlay.tsx" /* webpackChunkName: "component---src-pages-components-overlay-tsx" */),
  "component---src-pages-components-page-tsx": () => import("./../../../src/pages/components/Page.tsx" /* webpackChunkName: "component---src-pages-components-page-tsx" */),
  "component---src-pages-components-pager-tsx": () => import("./../../../src/pages/components/Pager.tsx" /* webpackChunkName: "component---src-pages-components-pager-tsx" */),
  "component---src-pages-components-performance-inspector-tsx": () => import("./../../../src/pages/components/PerformanceInspector.tsx" /* webpackChunkName: "component---src-pages-components-performance-inspector-tsx" */),
  "component---src-pages-components-radio-input-tsx": () => import("./../../../src/pages/components/RadioInput.tsx" /* webpackChunkName: "component---src-pages-components-radio-input-tsx" */),
  "component---src-pages-components-search-input-tsx": () => import("./../../../src/pages/components/SearchInput.tsx" /* webpackChunkName: "component---src-pages-components-search-input-tsx" */),
  "component---src-pages-components-segmented-control-tsx": () => import("./../../../src/pages/components/SegmentedControl.tsx" /* webpackChunkName: "component---src-pages-components-segmented-control-tsx" */),
  "component---src-pages-components-select-tsx": () => import("./../../../src/pages/components/Select.tsx" /* webpackChunkName: "component---src-pages-components-select-tsx" */),
  "component---src-pages-components-slider-tsx": () => import("./../../../src/pages/components/Slider.tsx" /* webpackChunkName: "component---src-pages-components-slider-tsx" */),
  "component---src-pages-components-switch-tsx": () => import("./../../../src/pages/components/Switch.tsx" /* webpackChunkName: "component---src-pages-components-switch-tsx" */),
  "component---src-pages-components-text-input-mask-tsx": () => import("./../../../src/pages/components/TextInputMask.tsx" /* webpackChunkName: "component---src-pages-components-text-input-mask-tsx" */),
  "component---src-pages-components-text-input-tsx": () => import("./../../../src/pages/components/TextInput.tsx" /* webpackChunkName: "component---src-pages-components-text-input-tsx" */),
  "component---src-pages-components-text-tsx": () => import("./../../../src/pages/components/Text.tsx" /* webpackChunkName: "component---src-pages-components-text-tsx" */),
  "component---src-pages-components-tooltip-tsx": () => import("./../../../src/pages/components/Tooltip.tsx" /* webpackChunkName: "component---src-pages-components-tooltip-tsx" */),
  "component---src-pages-components-touchable-tsx": () => import("./../../../src/pages/components/Touchable.tsx" /* webpackChunkName: "component---src-pages-components-touchable-tsx" */),
  "component---src-pages-components-view-tsx": () => import("./../../../src/pages/components/View.tsx" /* webpackChunkName: "component---src-pages-components-view-tsx" */),
  "component---src-pages-crudexample-tsx": () => import("./../../../src/pages/crudexample.tsx" /* webpackChunkName: "component---src-pages-crudexample-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-list-chat-tsx": () => import("./../../../src/pages/list/chat.tsx" /* webpackChunkName: "component---src-pages-list-chat-tsx" */),
  "component---src-pages-list-guests-tsx": () => import("./../../../src/pages/list/guests.tsx" /* webpackChunkName: "component---src-pages-list-guests-tsx" */),
  "component---src-pages-list-messages-tsx": () => import("./../../../src/pages/list/messages.tsx" /* webpackChunkName: "component---src-pages-list-messages-tsx" */),
  "component---src-pages-list-notifications-tsx": () => import("./../../../src/pages/list/notifications.tsx" /* webpackChunkName: "component---src-pages-list-notifications-tsx" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile/edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */)
}

