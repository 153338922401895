import {
  PaginationResponse,
  QueryManager,
  ReactQuery,
  TypeGuards,
} from "@codeleap/common";
import { api } from "@/app";
// import { Announcement } from '@/types'
import { queryClient } from "../queryClient";

const BASE_URL = "crm/announcement/";

type AnnouncementsFilters = {
  title?: string;
  hotel_id?: string;
};

const queryKeys = {
  announcements: ["announcements"],
};

export async function deleteAnnouncement(announcement) {
  await api.delete(`${BASE_URL}${announcement.id}/`);

  await queryClient.client.refetchQueries({
    queryKey: announcementsManager.queryKeys.list,
  });

  return announcement;
}

export const announcementsManager = new QueryManager({
  itemType: {} as any,
  name: "announcements",
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: AnnouncementsFilters) => {
    const response = await api.get<PaginationResponse<any>>(BASE_URL, {
      params: {
        ...filters,
        title: filters.title?.trim() || undefined,
        hotel_id: filters.hotel_id,
      },
    });

    return response.data;
  },

  createItem: async (data) => {
    const { image, ...rest } = data;

    const requestData = {
      data: {
        ...rest,
      },
      image: null,
    };

    if (!!image && !TypeGuards.isString(image)) {
      requestData.image = image;
    } else {
      requestData.image = "null";
    }

    const response = await api.post(BASE_URL, requestData, { multipart: true });

    return response.data;
  },

  updateItem: async (data) => {
    const { id, image, ...rest } = data;

    const requestData = {
      data: {
        ...rest,
      },
      image: null,
    };

    if (!!image && !TypeGuards.isString(image)) {
      requestData.image = image;
    } else {
      requestData.image = "null";
    }

    const response = await api.patch<PaginationResponse<any>>(
      `${BASE_URL}${id}/`,
      requestData,
      { multipart: true },
    );

    await queryClient.client.refetchQueries({
      queryKey: queryClient.queryKey(["announcements"]).key,
    });

    return response.data;
  },

  deleteItem: async (data) => {
    if (announcementsManager.meta.throwErrorForDelete) {
      throw new Error("Error deleting announcement");
    }

    await api.delete(`${BASE_URL}${data.id}/`);

    return data;
  },

  initialMeta: {
    optimisticLike: true,
    throwErrorForDelete: false,
    optimisticFavorite: true,
  },
});

export const useAnnouncements = () => {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.announcements,
  });

  return {
    query,
  };
};
