import { Theme, variantProvider } from "@/app";
import {
  Modal,
  View,
  Alert,
  ImageProps,
  Image,
  Text,
  Button,
  Link,
  Icon,
  Status,
} from "@/components";
import { AnyFunction } from "@codeleap/common";
import { IconProps } from "@codeleap/web";

type DetailsProps = {
  status?: "pending" | "confirmed" | "current" | "past";
  name?: string;
  email?: string;
  phoneNumber?: string;
  number?: string;
  title?: string;
  description?: string;
  link?: string;
  link_name?: string;
  image?: ImageProps["source"];
};

type ActionsButton = {
  onAction?: AnyFunction;
  onDismiss?: AnyFunction;
  onActionText?: string;
  actionIcon?: IconProps["name"];
  dismissIcon?: IconProps["name"];
  dismissText?: string;
};

type ActionModalProps = {
  toggle: AnyFunction;
  visible: boolean;
  debugName: string;
  type:
    | "info"
    | "destructive"
    | "action"
    | "status"
    | "status:action"
    | "status:delete";
  details?: DetailsProps;
  buttons?: ActionsButton;
};

export function ActionModal(props: ActionModalProps) {
  const { debugName, visible, type = "info", toggle, details, buttons } = props;

  const Component = type === "info" || type === "status" ? Modal : Alert;
  const hasImage = type === "info" && details?.image;

  return (
    <Component
      title={hasImage ? "" : details?.title}
      type={type === "info" || type === "destructive" ? type : "warn"}
      onAction={buttons?.onAction}
      onDismiss={type === "status:action" ? buttons?.onDismiss : toggle}
      debugName={debugName}
      visible={visible}
      isRow
      closeOnAction={type !== "status:action"}
      closeOnDismiss={type !== "status:action"}
      isAction={type !== "info"}
      showClose={type !== "info"}
      styles={{ box: { width: 480, padding: hasImage && 0 } }}
      showDismissButton={type !== "status:delete"}
      actionButtonProps={{
        debugName: "Modal Action Button",
        text: buttons?.onActionText,
        icon: buttons?.actionIcon,
        variants: [
          type === "destructive"
            ? "destructive"
            : type === "status:delete"
              ? "action:dismiss"
              : "action",
          "fullWidth",
        ],
      }}
      dismissButtonProps={{
        debugName: "Modal dismiss",
        onClick: type === "status:action" ? buttons?.onDismiss : toggle,
        text: buttons?.dismissText,
        icon: buttons?.dismissIcon,
        variants: [
          type === "destructive" || type === "action"
            ? "dismiss"
            : "action:dismiss",
        ],
      }}
      toggle={toggle}
      footer={
        type === "info" || type === "status" ? (
          <View
            variants={
              hasImage
                ? ["paddingHorizontal:2", "paddingBottom:2", "fullWidth"]
                : ["padding:0", "fullWidth"]
            }
          >
            <Button
              debugName="close modal"
              text={buttons?.dismissText}
              onPress={toggle}
              icon={type === "status" ? buttons?.dismissIcon : null}
              variants={[
                type === "status" ? "action:dismiss" : "action",
                "fullWidth",
              ]}
            />
          </View>
        ) : null
      }
    >
      {type === "status" ||
      type === "status:action" ||
      type === "status:delete" ? (
        <View
          variants={[
            "alignCenter",
            hasImage ? "padding:2" : "padding:0",
            "column",
            "gap:2",
          ]}
        >
          <View variants={["column", "fullWidth", "alignStart", "gap:1"]}>
            <Text text="Status" variants={["p2", "color:neutral5", "bold"]} />
            <Status status={details.status} />
          </View>
          <View variants={["column", "fullWidth", "alignStart", "gap:1"]}>
            <Text
              text="Guest name"
              variants={["p2", "color:neutral5", "bold"]}
            />
            <Text text={details?.name} variants={["p1", "color:neutral6"]} />
          </View>
          <View variants={["column", "fullWidth", "alignStart", "gap:1"]}>
            <Text text="Email" variants={["p2", "color:neutral5", "bold"]} />
            <Text text={details?.email} variants={["p1", "color:neutral6"]} />
          </View>
          <View variants={["column", "fullWidth", "alignStart", "gap:1"]}>
            <Text
              text="Phone number"
              variants={["p2", "color:neutral5", "bold"]}
            />
            <View variants={["alignCenter", "gap:1"]}>
              <Icon
                name="whatsapp"
                size={20}
                color={Theme.colors.light.secondary4}
              />
              <Text
                text={details?.phoneNumber}
                variants={["p1", "color:neutral6"]}
              />
            </View>
          </View>
          <View variants={["column", "fullWidth", "alignStart", "gap:1"]}>
            <Text
              text="Reservation number"
              variants={["p2", "color:neutral5", "bold"]}
            />
            <Text text={details?.number} variants={["p1", "color:neutral6"]} />
          </View>
        </View>
      ) : (
        <View variants={["column"]}>
          {hasImage ? (
            <Image
              objectFit={"cover"}
              style={styles.image}
              source={details?.image}
            />
          ) : null}
          <View
            variants={[
              "alignCenter",
              hasImage ? "padding:2" : "padding:0",
              "column",
              "gap:1",
            ]}
          >
            {hasImage ? (
              <Text text={details?.title} variants={["textCenter", "h4"]} />
            ) : null}
            <Text
              variants={[type === "info" ? "textCenter" : "textLeft"]}
              text={details?.description}
            />
            {type === "info" && details?.link ? (
              <Link
                openNewTab
                to={details?.link}
                variants={[
                  "textCenter",
                  "link",
                  "color:secondary4",
                  "noUnderline",
                ]}
                text={details?.link_name ? details?.link_name : "More info"}
              />
            ) : null}
          </View>
        </View>
      )}
    </Component>
  );
}

const IMAGE_HEIGHT = 180;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    image: {
      ...theme.presets.fullWidth,
      maxHeight: IMAGE_HEIGHT,
      borderTopLeftRadius: Theme.borderRadius.medium,
      borderTopRightRadius: Theme.borderRadius.medium,
    },
  }),
  true,
);
