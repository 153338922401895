import { api, AppForms, AppImages, Theme, variantProvider } from "@/app";
import { useForm } from "@codeleap/common";
import { Button, TextInput, Text, View, Logo } from "@/components";
import { APIClient } from "@/services";
import { navigate } from "gatsby";
import { useAppSelector } from "@/redux";

const BREAKPOINT = "mid";

export const Login = () => {
  const login = APIClient.Session.useLogin();
  const form = useForm(AppForms.login, {});
  const logoImage = AppImages.AppLogoMobile;

  const isDevelopment = useAppSelector((store) => store.AppStatus.isDev);

  const handleSubmit = async () => {
    await login(form.values);
  };

  return (
    <View
      style={styles.wrapper}
      variants={[
        "column",
        "alignStart",
        "bg:neutral1",
        "padding:4",
        "border-radius:medium",
      ]}
    >
      <View>
        <Logo
          switchServerOnPress
          image={logoImage}
          responsive={false}
          style={styles.logoImage}
          debugName={"login logo press"}
        />
        <Text text="BellHopp" variants={["h2", "bold", "marginLeft:1"]} />
      </View>
      <Text
        text="Login"
        variants={["h1", "bold", "textCenter", "marginBottom:1"]}
      />
      <Text
        text="Welcome, manager"
        variants={["p1", "color:neutral6", "textCenter", "bold"]}
      />

      <View variants={["column", "marginTop:4", "fullWidth"]}>
        <TextInput
          {...form.register("email")}
          variants={["explore:reservation"]}
          debugName={"Login email input"}
          leftIcon={{
            name: "mail",
          }}
        />

        <View variants={["relative"]}>
          <TextInput
            {...form.register("password")}
            variants={["explore:reservation"]}
            debugName={"Login password input"}
            visibilityToggle
            leftIcon={{
              name: "key",
            }}
          />

          <Button
            text={"Forgot password?"}
            onPress={() => navigate("/auth/forgot/")}
            style={styles.forgot}
            debugName={"ForgotPassword button"}
            variants={["action:minimal", "small", "absolute"]}
          />
        </View>
      </View>

      <View
        variants={["fullWidth", "gap:2", "column", "marginTop:1"]}
        responsiveVariants={{ [BREAKPOINT]: ["marginTop:auto"] }}
      >
        <Button
          onPress={handleSubmit}
          debugName={"Login"}
          disabled={!form.isValid}
          variants={["bg:primary5", "fullWidth"]}
        >
          <Text text="Enter" variants={["color:neutral1", "medium"]} />
        </Button>
        <Button
          onPress={() => navigate("/auth/signup/")}
          debugName={"Login"}
          variants={["action:dismiss", "gap:1"]}
        >
          <Text
            text="Don't have an account?"
            variants={["color:primary5", "bold"]}
          />
          <Text text="Sign Up" variants={["color:secondary4", "bold"]} />
        </Button>
        {isDevelopment && (
          <Text
            variants={["p4", "color:neutral4", "textCenter", "marginTop:2"]}
            text={`Using development server: ${api.config.baseURL}`}
          />
        )}
      </View>
    </View>
  );
};

const logoSize = 32;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      width: "100%",
      maxWidth: 560,
      height: "fit-content",

      [theme.media.down("mid")]: {
        ...theme.presets.fullView,
        padding: theme.spacing.value(2),
        borderRadius: theme.spacing.value(0),
      },
    },
    logoImage: {
      width: logoSize,
      marginBottom: theme.spacing.value(3),
      alignSelf: "flex-start",

      [Theme.media.down("mid")]: {
        alignSelf: "center",
      },
    },
    forgot: {
      right: theme.spacing.value(0),
      top: -theme.spacing.value(0.5),
    },
  }),
  true,
);

export default Login;
