import { api, AppForms, AppImages, Theme, variantProvider } from "@/app";
import { useForm } from "@codeleap/common";
import { Button, TextInput, Text, View, Logo } from "@/components";
import { navigate } from "gatsby";
import { APIClient } from "@/services";
import { useAppSelector } from "@/redux";

const BREAKPOINT = "mid";

export const SignUp = () => {
  const form = useForm(AppForms.signup, {});
  const logoImage = AppImages.AppLogoMobile;

  const isDevelopment = useAppSelector((store) => store.AppStatus.isDev);

  const hotel = APIClient.Hotel.hotelsManager.useCreate({});

  const signup = APIClient.Session.useSignup();

  const handleSignUp = async () => {
    try {
      const response = await signup(form.values);

      if (response?.user) {
        await hotel.create({
          title: form.values.full_name,
          profile: response.user.uid,
        });
      }
    } catch (err) {
      logger.error("Error creating account", err);
    }
  };

  return (
    <View
      style={styles.wrapper}
      variants={[
        "column",
        "alignStart",
        "bg:neutral1",
        "padding:4",
        "border-radius:medium",
      ]}
    >
      <View>
        <Logo
          switchServerOnPress
          image={logoImage}
          responsive={false}
          style={styles.logoImage}
          debugName={"sign up logo press"}
        />
        <Text text="BellHopp" variants={["h2", "bold", "marginLeft:1"]} />
      </View>
      <Text
        text="Sign Up"
        variants={["h1", "bold", "textCenter", "marginBottom:2"]}
      />
      <Text
        text="Welcome, manager"
        variants={["p1", "color:neutral6", "textCenter", "bold"]}
      />

      <View variants={["column", "marginTop:2", "fullWidth"]}>
        <TextInput
          {...form.register("full_name")}
          variants={["explore:reservation"]}
          debugName={"Login email input"}
        />
        <TextInput
          {...form.register("email")}
          variants={["explore:reservation"]}
          debugName={"Login email input"}
        />
        <TextInput
          {...form.register("password")}
          variants={["explore:reservation"]}
          debugName={"Login password input"}
          visibilityToggle
          leftIcon={{
            name: "key",
          }}
        />

        <TextInput
          {...form.register("repeatPassword")}
          variants={["explore:reservation"]}
          debugName={"Login password input"}
          visibilityToggle
          leftIcon={{
            name: "key",
          }}
        />
      </View>

      <View
        variants={["fullWidth", "gap:2", "column"]}
        responsiveVariants={{ [BREAKPOINT]: ["marginTop:auto"] }}
      >
        <Button
          onPress={handleSignUp}
          debugName={"Sign Up"}
          disabled={!form.isValid}
          variants={["bg:primary5", "fullWidth"]}
        >
          <Text text="Create account" variants={["color:neutral1", "medium"]} />
        </Button>
        <Button
          onPress={() => navigate("/auth/login/")}
          debugName={"Login"}
          variants={["action:dismiss", "gap:1"]}
        >
          <Text text="Have an account?" variants={["color:primary5", "bold"]} />
          <Text text="Login" variants={["color:secondary4", "bold"]} />
        </Button>
        {isDevelopment && (
          <Text
            variants={["p4", "color:neutral4", "textCenter", "marginTop:2"]}
            text={`Using development server: ${api.config.baseURL}`}
          />
        )}
      </View>
    </View>
  );
};

const logoSize = 32;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      width: "100%",
      maxWidth: 560,
      height: "fit-content",

      [theme.media.down("mid")]: {
        ...theme.presets.fullView,
        padding: theme.spacing.value(2),
        borderRadius: theme.spacing.value(0),
      },
    },
    logoImage: {
      width: logoSize,
      marginBottom: theme.spacing.value(3),
    },
  }),
  true,
);
