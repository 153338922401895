import { AppStatus, useAppSelector } from "@/redux";
import { getFirebase } from "../../firebaseApp";
import {
  onUpdate,
  ReactQuery,
  useIsomorphicEffect,
  useRef,
  useState,
} from "@codeleap/common";
import { create, retrieve, update } from "./views";
import { QueryKeys } from "./queryKeys";
import { useToggleDevMode } from "@/utils";
import { navigate } from "gatsby";
import { LocalStorageKeys } from "@/app";
import { CreateOSAlert } from "@codeleap/web";
import { OSAlert } from "@/components";

const warnEmailInUse = () => {
  // OSAlert.error({
  //   body: 'This email address is already taken',
  //   title: 'Email address in use',
  // })
  CreateOSAlert(OSAlert).error({
    title: "Email already in use",
    body: "We already have an account that uses that email address.",
  });
};

const SPLASH_TRANSITION = 1500;

export function useSession(root = false) {
  const devMode = useToggleDevMode(root);

  const authFinished = useAppSelector((store) => store.AppStatus.authFinished);
  const initialAuthResolved = useAppSelector(
    (store) => store.AppStatus.hasResolvedInitialAuth,
  );

  const [hasFirebaseUser, setHasFirebaseUser] = useState(null);

  const profile = ReactQuery.useQuery({
    queryKey: QueryKeys.me.key,
    queryFn: retrieve,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0;
    },
    retry: false,
    enabled: root && devMode.loaded,
    onError() {
      QueryKeys.me.setData(null);
    },
  });

  const createProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: create,
    onSuccess: () => {
      QueryKeys.me.refresh();
    },
  });

  const updateProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: update,
    onSuccess: () => {
      QueryKeys.me.refresh();
    },
  });

  const logout = async () => {
    AppStatus.set("splash");

    const firebase = await getFirebase();

    setTimeout(() => {
      firebase.auth().signOut();

      setTimeout(() => {
        QueryKeys.me.setData(null);

        AppStatus.set("idle");
      }, 1000);
    }, 2000);
  };

  const loginResolved = typeof profile.data !== "undefined";

  const onOnboardingFinished = () => {
    AppStatus.authFinished();
  };

  const onInitialAuthResolved = () => {
    AppStatus.set("splash");

    setTimeout(() => {
      AppStatus.initialAuthResolved();
    }, 35000);
  };

  const requestPasswordReset = async (email: string) => {
    await AppStatus.set("loading");

    try {
      const firebase = await getFirebase();
      await firebase.auth().sendPasswordResetEmail(email);
      setTimeout(() => {
        AppStatus.set("done");
      }, 2000);
    } catch (e) {
      await AppStatus.set("idle");
      console.error("Password reset", e, "Auth");
      CreateOSAlert(OSAlert).error({
        title: "Something went wrong",
        body: "There was an error during password reset",
      });
      throw e;
    }
  };

  const reauthenticate = async (password: string) => {
    const firebase = await getFirebase();

    return firebase
      .auth()
      .signInWithEmailAndPassword(profile.data.email, password);
  };

  const resolvedAuth = useRef(false);

  useIsomorphicEffect(() => {
    if (!root) return;

    let unsubscribe: () => void;

    const init = async () => {
      const firebase = await getFirebase();

      unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user?.uid) {
          localStorage.setItem(LocalStorageKeys.PERSIST_AUTH, "true");
        } else {
          localStorage.removeItem(LocalStorageKeys.PERSIST_AUTH);
        }

        if (user) {
          setHasFirebaseUser(user?.uid);

          setTimeout(() => {
            QueryKeys.me.refresh();
          }, 1500);
        } else {
          await QueryKeys.me.setData(null);
        }

        if (!resolvedAuth.current) {
          setTimeout(() => {
            AppStatus.set("idle");
          }, SPLASH_TRANSITION);
        }

        resolvedAuth.current = true;
      });
    };

    init();
  }, []);

  onUpdate(() => {
    if (!profile.isFetched && root && devMode.loaded) {
      profile
        .refetch()
        .then((result) => {
          if (result.isSuccess) {
            AppStatus.authFinished();
          }
        })
        .catch(() => {});
    }
  }, [devMode.loaded]);

  onUpdate(() => {
    if (loginResolved && root && !initialAuthResolved) {
      onInitialAuthResolved();
    }
  }, [loginResolved]);

  return {
    profile: profile.data,
    reauthenticate,
    updateProfile,
    createProfile,
    logout,
    isLoggedIn: !!profile.data,
    loginResolved,
    authFinished,
    onOnboardingFinished,
    requestPasswordReset,
    warnEmailInUse,
    profileQuery: profile,
    hasFirebaseUser,
  };
}
