import { AppForms, AppImages, Theme, variantProvider } from "@/app";
import { useForm, useState } from "@codeleap/common";
import { Button, TextInput, Text, View, Logo } from "@/components";
import { APIClient } from "@/services";
import { navigate } from "gatsby";
import { useInterval } from "@/utils";

const BREAKPOINT = "mid";

const Page1 = ({ form, onRequest }) => {
  const { requestPasswordReset } = APIClient.Session.useSession();

  const handleSubmit = async () => {
    await requestPasswordReset(form.values.email);

    onRequest();
  };

  return (
    <View variants={["column"]}>
      <Text text="Reset Password" variants={["h1", "bold", "marginBottom:1"]} />
      <Text
        text="Enter your registered email below to receive password reset instructions."
        variants={["p1", "color:neutral6", "bold"]}
      />

      <TextInput
        {...form.register("email")}
        variants={["explore:reservation", "marginTop:4", "fullWidth"]}
        debugName={"Email forgot password"}
      />

      <View
        variants={["fullWidth", "gap:2", "column", "marginTop:1"]}
        responsiveVariants={{ [BREAKPOINT]: ["marginTop:auto"] }}
      >
        <Button
          onPress={handleSubmit}
          debugName={"Send forgot password email"}
          disabled={!form.isValid}
          variants={["bg:primary5", "fullWidth"]}
        >
          <Text text="Send" variants={["color:neutral1", "medium"]} />
        </Button>
        <Button
          onPress={() => navigate("/auth/login/")}
          debugName={"Back to Login"}
          variants={["action:dismiss", "gap:1"]}
        >
          <Text text="Back to login" variants={["color:primary5", "bold"]} />
        </Button>
      </View>
    </View>
  );
};

const INTERVAL = 1000;
const ResendButton = ({ form }) => {
  const [timer, setTimer] = useState(0);
  const isCounting = timer > 0;
  const { requestPasswordReset } = APIClient.Session.useSession();

  const handleSubmit = async () => {
    await requestPasswordReset(form.values.email);
    setTimer(30);
  };

  useInterval(
    () => isCounting && setTimer((s) => s - 1),
    isCounting ? INTERVAL : null,
  );

  return (
    <Button
      onPress={handleSubmit}
      debugName={"Resend Forgot Password email"}
      variants={["action:dismiss", "gap:1"]}
      disabled={isCounting}
    >
      {isCounting ? (
        <Text
          text={`Wait ${timer} seconds to resend email`}
          variants={["color:primary5", "bold"]}
        />
      ) : (
        <View variants={["gap:1"]}>
          <Text text="Didn't receive?" variants={["color:primary5", "bold"]} />
          <Text text="Resend email" variants={["color:secondary4", "bold"]} />
        </View>
      )}
    </Button>
  );
};

const Page2 = ({ form }) => {
  return (
    <View variants={["column"]}>
      <Text
        text="Check your inbox"
        variants={["h1", "bold", "marginBottom:1"]}
      />
      <View variants={["marginVertical:3", "column", "gap:2"]}>
        <Text
          text="We're checking through our records to find the account for your email address. If we find a match, you'll get an email with further instructions."
          variants={["p1", "color:neutral6"]}
        />
        <Text
          text="If you don't hear from us in the next 15 minutes, please double check that you entered the right email address, and check your spam folder, or you can try pressing the button below."
          variants={["p1", "color:neutral6"]}
        />
      </View>

      <View
        variants={["fullWidth", "gap:2", "column", "marginTop:1"]}
        responsiveVariants={{ [BREAKPOINT]: ["marginTop:auto"] }}
      >
        <ResendButton form={form} />

        <Button
          onPress={() => navigate("/auth/login/")}
          debugName={"Back to Login"}
          variants={["minimal"]}
        >
          <Text text="Login" variants={["color:secondary4", "bold"]} />
        </Button>
      </View>
    </View>
  );
};

export const ForgotPassword = () => {
  const form = useForm(AppForms.forgotPassword, {});
  const logoImage = AppImages.AppLogoMobile;
  const [page, setPage] = useState(1);

  const toggleForm = () => setPage(page === 2 ? 1 : 2);

  return (
    <View
      style={styles.wrapper}
      variants={[
        "column",
        "alignStart",
        "bg:neutral1",
        "padding:4",
        "border-radius:medium",
      ]}
    >
      <View>
        <Logo style={styles.logoImage} responsive={false} image={logoImage} />
        <Text text="BellHopp" variants={["h2", "bold", "marginLeft:1"]} />
      </View>
      {page === 1 ? (
        <Page1 form={form} onRequest={toggleForm} />
      ) : (
        <Page2 form={form} />
      )}
    </View>
  );
};

const logoSize = 32;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      width: "100%",
      maxWidth: 560,
      height: "fit-content",

      [theme.media.down("mid")]: {
        ...theme.presets.fullView,
        padding: theme.spacing.value(2),
      },
    },
    logoImage: {
      width: logoSize,
      marginBottom: theme.spacing.value(3),
      alignSelf: "flex-start",

      [Theme.media.down("mid")]: {
        alignSelf: "center",
      },
    },
  }),
  true,
);
