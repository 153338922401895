import { createRedux } from "@codeleap/common";
import { appStatusSlice } from "./AppStatus";
import { TypedUseSelectorHook, useSelector } from "react-redux";

const middlewares = [];

// if (__DEV__) {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const reduxDebugger = require('redux-flipper').default
//   middlewares.push(reduxDebugger({}))
// }

export const {
  store,
  actions: { AppStatus },
} = createRedux(
  {
    AppStatus: appStatusSlice,
  },
  {},
);

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export * from "./AppStatus";
