import { useSession } from "./useSession";
// import { OSAlert } from '@codeleap/mobile'
import { Profile } from "./views";

/*
   TODO

   Add an OSAlert equivalent to the web app
   Test this function
*/
export function useDeleteAccount() {
  const { logout } = useSession();

  const onDeleteAccount = (id: Profile["id"]) => {
    // OSAlert.ask({
    //   title: 'Delete Account',
    //   body: `Are you sure you want to delete your account? This will permanently erase your account`,
    //   options: [
    //     {
    //       text: 'Cancel',
    //       onPress: () => {},
    //     },
    //     {
    //       onPress() {
    //         onFinalDeleteAccount(id)
    //       },
    //       text: 'Remove',
    //     },
    //   ],
    // })
  };

  const onFinalDeleteAccount = (id: Profile["id"]) => {
    // OSAlert.ask({
    //   title: 'Confirm Deletion',
    //   body: `Your account will be deleted immediately. You cannot undo this action. Are you sure you want to proceed?`,
    //   options: [
    //     {
    //       text: 'Cancel',
    //       onPress: () => {},
    //     },
    //     {
    //       onPress: async () => {
    //         await logout()
    //         await APIClient.Session.deleteProfile(id)
    //         await withFirebase(async ({auth}) => {
    //           return auth().signOut()
    //         })
    //       },
    //       text: 'Delete',
    //       style: 'destructive',
    //     },
    //   ],
    // })
  };

  return {
    onDeleteAccount,
  };
}
