import { api, logger } from "@/app";
import { ChatMessagesResponse, ConversationResponse } from "@/types";

const MODULE = "chat/";

export async function createConversation(
  hotelId: number,
  senderId: string,
  receiverId: string,
): Promise<ConversationResponse> {
  try {
    logger.info(`createConversation ${hotelId} ${senderId} ${receiverId}`);
    const result = await api.post<ConversationResponse>(
      `${MODULE}/conversations/`,
      {
        hotel: hotelId,
        participants: [senderId, receiverId],
      },
    );
    return result.data;
  } catch (err) {
    logger.error({ err });
    return null;
  }
}

export async function listConversations(
  hotelId: string,
): Promise<ConversationResponse[]> {
  try {
    logger.info(`listConversations ${hotelId}`);
    const result = await api.get<ConversationResponse[]>(
      `${MODULE}conversations/?hotel_id=${hotelId}`,
    );
    return result.data;
  } catch (err) {
    logger.error({ err });
    return null;
  }
}

export async function listMessagesByConversationId(
  conversationId: number,
): Promise<ChatMessagesResponse[]> {
  try {
    const result = await api.get<ChatMessagesResponse[]>(
      `${MODULE}/messages?conversation_id=${conversationId}`,
    );
    return result.data;
  } catch (err) {
    logger.error({ err });
    return null;
  }
}

export async function sendMessage(
  conversationId: number,
  text: string,
): Promise<ChatMessagesResponse[]> {
  try {
    logger.info(`sendMessage ${conversationId} ${text}`);
    const result = await api.post<ChatMessagesResponse[]>(
      `${MODULE}/messages/`,
      {
        conversation: conversationId,
        text,
      },
    );
    return result.data;
  } catch (err) {
    logger.error({ err });
    return null;
  }
}

export async function markMessageAsRead(
  messageId: string,
): Promise<ChatMessagesResponse[]> {
  try {
    const result = await api.patch<ChatMessagesResponse[]>(
      `${MODULE}messages/${messageId}/`,
      {
        is_read: true,
      },
    );
    return result.data;
  } catch (err) {
    logger.error({ err });
    return null;
  }
}
