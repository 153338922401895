import { api } from "@/app";
import {
  getPaginationKeys,
  onUpdate,
  usePagination,
  UsePaginationTypes,
} from "@codeleap/common";
import { queryClient } from "../queryClient";
import { notificationManager, parseFirebaseNotification } from "./firebase";
import { Notification, STATUS_TYPES } from "./types";

export * from "./types";
export * from "./firebase";
export * from "./handler";

export async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<
    UsePaginationTypes.PaginationReturn<Notification>
  >("", {
    params,
  });

  return response.data;
}

export async function registerDevice(token: string) {
  const data = {
    registration_id: token,
    cloud_message_type: "FCM",
    active: !!token,
  };

  logger.info("Register device", { data }, "notifications");
  await api.post("device/", data);
}

export const QueryKeys = getPaginationKeys("notifications", queryClient);

export function useNotifications() {
  const pagination = usePagination<Notification>(QueryKeys, {
    keyExtractor: (item) => item.id,
    limit: 20,
    onList: list,
    deriveData({ context, currentData, item }) {
      if (!context.passedFilter) return currentData;

      const add = item.status === STATUS_TYPES.Sent ? 1 : 0;

      return {
        ...currentData,
        unreadCount: (currentData?.unreadCount || 0) + add,
      };
    },
  });

  onUpdate(() => {
    return notificationManager.onNotification((message, type) => {
      if (type === "initial" || type === "press") return;
      const notification = parseFirebaseNotification(message);

      pagination.objects.append({
        item: notification,
        to: "start",
      });
    });
  }, [pagination.items.length]);
  return {
    ...pagination,
    unreadCount: pagination.derivedData?.unreadCount as number,
  };
}
