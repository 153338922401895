import { capitalize } from "@codeleap/common";
import faker from "@faker-js/faker";
import { IsDevEnv } from "./Settings";

const fakeEmail = `tester+${faker.animal.type()}_${faker.datatype.number({ min: 0, max: 100 })}@codeleap.co.uk`;
const fakeFirst = `${faker.name.firstName()}`;
const fakeLast = `${faker.name.lastName()}`;

export const fakeProfile = {
  email: IsDevEnv ? fakeEmail : "",
  password: IsDevEnv ? "pppppp" : "",
  repeatPassword: IsDevEnv ? "pppppp" : "",
  avatar: IsDevEnv ? null : null,
  first_name: IsDevEnv ? fakeFirst : "",
  last_name: IsDevEnv ? fakeLast : "",
};

const _testers = {
  default: {
    email: "tester@codeleap.co.uk",
    password: "pppppp",
  },
  random: fakeProfile,
};

const mailList = [
  "paulo",
  "gabriel",
  "felipe",
  "victor",
  "vini",
  "fernando",
  "leo",
  "lucas",
  "kenzo",
].sort((a, b) => a.localeCompare(b));

mailList.forEach((person) => {
  _testers[person] = {
    email: `${person}@codeleap.co.uk`,
    password: "pppppp",
    first_name: capitalize(person),
    last_name: "Codeleap",
  };
});

export const testers = _testers;

export const testerOptions = Object.keys(_testers).map((testerId) => {
  return {
    value: testerId,
    label: capitalize(testerId),
  };
});
