import { Link, Text, View } from "@/components";
import { AnyFunction } from "@codeleap/common";

type RedirectLinkProps = {
  to: string;
  text: string;
  textAction: string;
};

export const RedirectLink = (props: RedirectLinkProps) => {
  const { to, text, textAction } = props;
  return (
    <Link
      to={to}
      variants={[
        "noUnderline",
        "color:neutral10",
        "alignSelfCenter",
        "marginTop:2",
      ]}
    >
      {text}{" "}
      <Text
        component="span"
        variants={["textCenter", "color:primary3"]}
        text={textAction}
      />
    </Link>
  );
};

type RedirectModalProps = {
  text: string;
  textAction: string;
  onPress: AnyFunction;
};

export const RedirectModal = (props: RedirectModalProps) => {
  const { onPress, text, textAction } = props;
  return (
    <View
      variants={["row", "alignCenter", "justifyCenter", "marginTop:2", "gap:1"]}
    >
      <Text variants={["textCenter"]} text={text} />
      <Text
        text={textAction}
        onPress={onPress}
        variants={["color:primary3", "cursor:pointer"]}
      />
    </View>
  );
};

export const AuthFormComponents = {
  RedirectLink,
  RedirectModal,
};
