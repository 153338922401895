import React from "react";
import { View, Text, Button, Drawer } from "@/components";
import { useBooleanToggle } from "@codeleap/common";
import { useMediaQuery } from "@codeleap/web";
import { Theme } from "@/app";

type ComponentShowCase = {
  Preview: () => JSX.Element;
  name?: string;
  children?: React.ReactNode;
};

export const ComponentShowCase = ({
  Preview,
  name = "Example",
  children,
}: ComponentShowCase) => {
  const isMobile = useMediaQuery(Theme.media.down("small"));

  return (
    <View
      variants={["column", "flex", "paddingHorizontal:1", "paddingVertical:1"]}
    >
      <Text text={name} variants={["h3", "marginBottom:3"]} />

      <View variants={[isMobile ? "column" : "row", "gap:2", "justifyCenter"]}>
        {children ?? <Preview />}
      </View>
    </View>
  );
};

type ComponentControlProps = {
  control?: {
    Component: React.FC<{}>;
    props: any;
  }[];
  children?: React.ReactNode;
  gap?: number;
  variants?: string[];
};

export const ComponentControl = ({
  control = [],
  children,
  gap = 0,
  variants = [],
}: ComponentControlProps) => {
  const isMobile = useMediaQuery(Theme.media.down("mid"));
  const [visible, toggle] = useBooleanToggle(false);

  const Controls = () => (
    <View
      variants={["gap:2", "column", "flex"]}
      css={{ maxWidth: isMobile ? "100%" : "30vw" }}
    >
      {control?.map(({ Component, props }, key) => {
        return (
          <View variants={["gap:2", "column"]} key={key}>
            <Component {...props} />
          </View>
        );
      })}
    </View>
  );

  return (
    <View
      variants={["fullWidth", "row", "gap:2"]}
      responsiveVariants={{
        mid: ["column"],
      }}
    >
      {isMobile && (
        <Button
          debugName="open component control"
          text="Open Component Control"
          onPress={toggle}
          variants={["marginBottom:2"]}
        />
      )}

      <View variants={["column", "flex", `gap:${gap}`, ...(variants as any)]}>
        {children}
      </View>

      {!isMobile && <Controls />}

      <Drawer
        open={visible}
        toggle={toggle}
        title={"Controls"}
        position={"right"}
        size="85vw"
        showCloseButton
      >
        <Controls />
      </Drawer>
    </View>
  );
};
