import { List } from "@/components";
import { IconPlaceholder, PropsOf, usePagination } from "@codeleap/common";
import { ListProps, PaginationIndicator } from "@codeleap/web";

type PaginationFlatListProps<TData = any> = Pick<
  ListProps<PropsOf<typeof List>, TData>,
  | "onRefresh"
  | "data"
  | "renderItem"
  | "placeholder"
  | "keyExtractor"
  | "ListFooterComponent"
  | "refreshing"
  | "ListLoadingIndicatorComponent"
>;

export function getFlatListProps<P extends ReturnType<typeof usePagination>>(
  pagination: P,
): PaginationFlatListProps<P["items"][number]> {
  const listQuery = pagination.queries.list;

  return {
    ...listQuery,
    data: pagination?.items,
    renderItem: () => null,
    keyExtractor: (item) => pagination.params.keyExtractor(item).toString(),
    refreshing: listQuery.isRefreshing,
    placeholder: {
      loading: listQuery.isFetching,
      icon: "placeholderNoItems-select" as IconPlaceholder,
    },
    onRefresh: () => listQuery.refresh(),
    ListLoadingIndicatorComponent: () => {
      const limited = pagination?.items?.length <= pagination?.options?.limit;

      if (!pagination?.items?.length || limited) return null;

      return (
        <PaginationIndicator
          hasMore={listQuery.hasNextPage}
          noMoreItemsText={`No more ${pagination.itemName}`}
          isFetching={listQuery.isFetchingNextPage}
        />
      );
    },
  };
}
