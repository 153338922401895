import { AppImages, Theme, variantProvider } from "@/app";
import { View, Logo, Text } from "@/components";

const BREAKPOINT = "mid";

export function AppLogo() {
  return (
    <View>
      <Logo
        style={styles.logoImage}
        responsive={false}
        image={AppImages.AppLogoMobile}
      />
      <View up={BREAKPOINT}>
        <Text text="BellHopp" variants={["h2", "bold", "marginLeft:1"]} />
      </View>
    </View>
  );
}

const logoSize = 32;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    logoImage: {
      width: logoSize,
      marginBottom: theme.spacing.value(3),
      alignSelf: "flex-start",

      [Theme.media.down("mid")]: {
        alignSelf: "center",
      },
    },
  }),
  true,
);
