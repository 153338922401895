export const imageIcons = {
  apple: require("./apple.svg"),
  archive: require("./archive.svg"),
  bell: require("./bell.svg"),
  chat: require("./chat_grey.png"),
  send: require("./send.png"),
  send_32: require("./send_32.png"),
  send_96: require("./send_96_grey.png"),
  bug: require("./bug.svg"),
  camera: require("./camera.svg"),
  check: require("./check.svg"),
  "chevron-down": require("./chevron-down.svg"),
  "chevron-left": require("./chevron-left.svg"),
  "chevron-right": require("./chevron-right.svg"),
  "chevron-up": require("./chevron-up.svg"),
  clock: require("./clock.svg"),
  "cloud-lightning": require("./cloud-lightning.svg"),
  cloud: require("./cloud.svg"),
  contact: require("./contact.svg"),
  contrast: require("./contrast.svg"),
  "edit-2": require("./edit-2.svg"),
  edit: require("./edit.svg"),
  "eye-off": require("./eye-off.svg"),
  eye: require("./eye.svg"),
  facebook: require("./facebook.svg"),
  "file-text": require("./file-text.svg"),
  file: require("./file.svg"),
  fingerprint: require("./fingerprint.svg"),
  folder: require("./folder.svg"),
  google: require("./google.svg"),
  heart: require("./heart.svg"),
  image: require("./image.svg"),
  instagram: require("./instagram.svg"),
  info: require("./info.svg"),
  key: require("./key.svg"),
  "lamp-floor": require("./lamp-floor.svg"),
  languages: require("./languages.svg"),
  layers: require("./layers.svg"),
  leaf: require("./leaf.svg"),
  "loader-2": require("./loader-2.svg"),
  linkedin: require("./linkedin.svg"),
  loader: require("./loader.svg"),
  "log-out": require("./log-out.svg"),
  link: require("./link.svg"),
  mail: require("./mail.svg"),
  "map-pin": require("./map-pin.svg"),
  menu: require("./menu.svg"),
  "message-circle": require("./message-circle.svg"),
  mic: require("./mic.svg"),
  minus: require("./minus.svg"),
  "more-vertical": require("./more-vertical.svg"),
  "placeholderNoItems-select": require("./placeholderNoItems_select.png"),
  "placeholder-select": require("./placeholder_select.png"),
  plus: require("./plus.svg"),
  search: require("./search.svg"),
  settings: require("./settings.svg"),
  star: require("./star.svg"),
  trash: require("./trash.svg"),
  user: require("./user.svg"),
  users: require("./users.svg"),
  "wifi-off": require("./wifi-off.svg"),
  wifi: require("./wifi.svg"),
  whatsapp: require("./whatsapp.svg"),
  x: require("./x.svg"),
};
