import { PaginationResponse, QueryManager, ReactQuery } from "@codeleap/common";
import { queryClient } from "../queryClient";
import { api } from "@/app";

export * from "./announcements";
export * from "./reservations";

const BASE_URL = "hotel/";

const queryKeys = {
  hotels: ["hotel"],
};

export const hotelsManager = new QueryManager({
  itemType: {} as any,
  name: "hotels",
  queryClient: queryClient.client,

  listItems: async (limit, offset) => {
    const response = await api.get<PaginationResponse<any>>(BASE_URL);

    return response.data;
  },

  createItem: async (data) => {
    const response = await api.post(BASE_URL, data);

    await queryClient.client.refetchQueries({
      queryKey: queryClient.queryKey(["me"]).key,
    });

    return response.data;
  },

  retrieveItem: async () => {
    const response = await api.get<PaginationResponse<any>>(BASE_URL);

    return response.data;
  },
});

export const useAnnouncements = () => {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.hotels,
  });

  return {
    query,
  };
};
