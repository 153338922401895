import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Image } from "../../../components/Image";
import { logger } from "../../logger";
import { imageIcons } from "./exports";

export const iconImages = {
  ...imageIcons,
};

type ExportedIcon = keyof typeof iconImages;

const applyFill: ExportedIcon[] = ["facebook", "apple"];

const ignoreStyles: ExportedIcon[] = ["google"];

export const RenderIcon = ({
  path,
  name = "",
  style = {},
  log,
  ...otherProps
}: any) => {
  const {
    size: stylesSize,
    width,
    height,
    color: stylesColor,
    ...otherStyles
  } = otherProps?.forceStyle ?? style;
  const { size: propsSize, color: propsColor } = otherProps;

  const styles = {
    ...otherStyles,
    height: !propsSize ? stylesSize ?? height : propsSize,
    width: !propsSize ? stylesSize ?? width : propsSize,
    color: propsColor ?? stylesColor,
  };

  if (typeof path == "object" && !!path?.default) {
    path = path.default;
  }

  if (typeof path === "function") {
    if (log) {
      logger.log("Icon style for " + name, otherStyles, "Component style");
    }

    const isApplyFill = applyFill.includes(name as any);

    const isIgnoreStyle = ignoreStyles.includes(name as any);

    let forceStroke: object = {
      "& > *": {
        stroke: `${styles.color} !important`,
      },
    };

    if (isIgnoreStyle) {
      forceStroke = null;
    } else if (isApplyFill) {
      forceStroke = {
        "& > *": {
          fill: `${styles.color} !important`,
        },
      };
    }

    const Component = path;

    return (
      <Component
        size={propsSize}
        color={propsColor}
        {...otherProps}
        css={[styles, forceStroke]}
      />
    );
  }

  const appliedStyles = {
    height: styles.height,
    width: styles.width,
    fontSize: styles.height || styles.width,
    tintColor: styles.color || null,
    color: styles.color || null,
  };

  if (log) {
    logger.log("Icon style for " + name, appliedStyles, "Component style");
  }
  return <Image source={path} css={appliedStyles} />;
};

type IconsType = {
  [Property in keyof typeof iconImages]: React.FC;
};

export type AppIcon = keyof IconsType;

export const IconNames = Object.keys(iconImages) as AppIcon[];

export const Icons = Object.fromEntries(
  Object.entries(iconImages).map(([iconName, iconPath]) => {
    return [
      iconName,
      (props) => (
        <RenderIcon
          path={iconPath}
          name={iconName}
          log={[].includes(iconName)}
          {...props}
        />
      ),
    ];
  }),
) as IconsType;
