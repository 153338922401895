import { IsDevEnv, LocalStorageKeys, React } from "@/app";
import { APIClient } from "@/services";
import { navigate } from "gatsby";
import { useIsomorphicEffect, useState } from "@codeleap/common";
import { LoadingScreen } from "@/components";
import { useReadyTimer } from "@/utils";

export type AuthenticatedScreenProps = {
  children: React.ReactNode;
  redirectOnFailure?: boolean;
  redirectPath?: string;
  showLoading?: boolean;
  autoRedirect?: boolean;
};

const READY_TIMEOUT = 10000;
const FETCH_COUNT_LIMIT = 4;
const FETCH_DELAY = 500;
const DEBUG = IsDevEnv && false;

export const AuthenticatedScreen = (props?: AuthenticatedScreenProps) => {
  const {
    children,
    redirectOnFailure = false,
    redirectPath = "/auth/login",
    autoRedirect = false,
  } = props;

  const {
    isLoggedIn,
    loginResolved,
    profileQuery,
    hasFirebaseUser,
    logout,
    profile,
  } = APIClient.Session.useSession();

  const [fetchCounter, setFetchCounter] = useState(0);
  const [ready, setReady] = useState(false);

  const invalidateSession = async () => {
    if (DEBUG) {
      logger.log("invalidateSession", {
        fetchCounter,
        ready,
        isLoggedIn,
        hasFirebaseUser,
        hasTimer: readyTimer.hasTimer,
      });
    }

    await logout().then(() => {
      setReady(true);
      setFetchCounter(0);
    });
  };

  const readyTimer = useReadyTimer(ready, invalidateSession, READY_TIMEOUT);

  const invalidateSessionVerification = () => {
    if (readyTimer.hasTimer || fetchCounter !== 0) {
      if (DEBUG) {
        logger.log("invalidateSessionVerification", {
          fetchCounter,
          ready,
          hasTimer: readyTimer.hasTimer,
        });
      }

      setFetchCounter(0);
      readyTimer.cancel();
    }

    if (!loginResolved && !isLoggedIn) {
      navigate(redirectPath);
    }
  };

  const onRedirect = () => {
    if (loginResolved && !isLoggedIn && redirectOnFailure) {
      navigate(redirectPath);
    }
  };
  const rehydrateSession = async () => {
    await profileQuery.refetch();
    onRedirect();
  };

  useIsomorphicEffect(() => {
    rehydrateSession();

    const isAuthReady = localStorage.getItem(LocalStorageKeys.PERSIST_AUTH);

    if (!isAuthReady && !isLoggedIn && autoRedirect) {
      navigate(redirectPath);
      return;
    }

    if (isAuthReady === "true" && !!isLoggedIn) {
      setReady(true);
    } else if (!isAuthReady && !isLoggedIn) {
      navigate(redirectPath);
    }
  }, [isLoggedIn, hasFirebaseUser]);

  const verifySession = async () => {
    if (profile?.id) return;

    const _fetch = await profileQuery.refetch();

    if (DEBUG) {
      logger.log("verifySession", { data: _fetch?.data, fetchCounter, ready });
    }

    if (!_fetch?.data?.id) {
      setFetchCounter((counter) => counter + 1);
    }
  };

  useIsomorphicEffect(() => {
    if (ready) {
      invalidateSessionVerification();
      return;
    }

    if (fetchCounter > FETCH_COUNT_LIMIT) {
      readyTimer.trigger();
    } else {
      setTimeout(() => {
        verifySession();
      }, FETCH_DELAY);
    }
  }, [fetchCounter, ready]);

  if (!ready) {
    return <LoadingScreen />;
  }
  return <>{children}</>;
};
