import { api } from "@/app";
import { getFirebase } from "../../firebaseApp";
import { TypeGuards } from "@codeleap/common";
import { AnyFile } from "@codeleap/common/dist/tools/Form/types";
// import { ImageProps, OSAlert } from '@codeleap/mobile'

export type Profile = {
  id: string;
  avatar: AnyFile;
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  is_dev: boolean;
  hotel: any;
};

export const ERRORS = {
  emailInUse: new Error("auth/email-already-in-use"),
  notRegistered: new Error("auth/not-registered"),
  reautenticationRequired: new Error("auth/requires-recent-login"),
};

const BASE_URL = "profiles/";

export async function create(data: Partial<Profile>) {
  const { avatar, ...profileData } = data;

  const response = await api.post<Profile>(
    `${BASE_URL}create/`,
    {
      data: profileData,
      files: avatar,
    },
    { multipart: true, debug: true },
  );

  return response.data;
}

export async function isEmailAvailable(email: string) {
  const res = await api.get(`${BASE_URL}check_email/`, {
    params: {
      email,
    },
    validateStatus: () => true,
  });

  return res.status.toString().startsWith("2");
}

export async function update(profile: Partial<Profile>) {
  const { avatar, ...data } = profile;

  const response = await api.patch<Profile>(
    `${BASE_URL}${profile.id}/`,
    {
      data,
      files: !!avatar ? avatar?.file : null,
    },
    {
      multipart: true,
      debug: true,
    },
  );

  return response.data;
}

export async function retrieve() {
  const response = await api.get<Profile>(`${BASE_URL}i/`);

  return response.data;
}

export async function requestPasswordReset(email: string) {
  const available = await isEmailAvailable(email);
  if (available) {
    // OSAlert.error({
    //   body: 'Could not find an account matching the specified email address',
    //   title: 'Unknown email',
    // })
  }
  try {
    const firebase = await getFirebase();
    await firebase.auth().sendPasswordResetEmail(email?.trim());
  } catch (e) {
    logger.error(e);

    // OSAlert.error({
    //   body: 'Error requesting reset',
    //   title: 'Please try again',
    // })
  }
}

export async function deleteProfile(id: Profile["id"]) {
  const response = await api.delete(`${BASE_URL}${id}/`);
  return response.data;
}

export async function registerDevice(token: string) {
  const data = {
    registration_id: token,
    cloud_message_type: "FCM",
    active: !!token,
  };

  logger.info("Register device", { data }, "notifications");
  await api.post("notifications/device/", data);
}
