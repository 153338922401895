import { React, AppForms } from "@/app";
import { View, Text, Button, TextInput } from "@/components";
import { useForm, onUpdate } from "@codeleap/common";
import { forwardRef, useImperativeHandle } from "react";
import { APIClient } from "@/services";
import { SocialButton } from "./SocialButton";
import { AuthFormProps } from "@/types";

const auths = {
  login: {
    form: AppForms.login,
    handler: "useLogin",
  },
  signup: {
    form: AppForms.signup,
    handler: "useSignup",
  },
  forgot: {
    form: AppForms.forgotPassword,
    handler: "useSession",
  },
};

export const AuthForm = forwardRef((props: AuthFormProps, ref) => {
  const {
    fields,
    onFormSwitch = null,
    onAuthSuccess = null,
    modal = false,
    visible = false,
    showSocial = true,
    type = "login",
    buttons = [],
    Footer = null,
    title,
    subTitle = null,
    submitText = "Continue",
  } = props as AuthFormProps;

  const { isLoggedIn } = APIClient.Session.useSession();

  const currentForm = auths[type];

  const form = useForm(currentForm.form());

  useImperativeHandle(ref, () => {
    return {
      setForm: (values) => {
        form.setFormValues(values);
      },
    };
  }, []);

  const authHandler = APIClient.Session[currentForm.handler]();

  const handleSubmit = () => {
    if (type === "forgot") {
      authHandler.requestPasswordReset(form.values.email);
    } else {
      authHandler(form.values);
    }
  };

  const handleSocial = async (val) => {
    const userSocial = await APIClient.Session.socialLogin({ provider: val });

    if (userSocial) {
      onFormSwitch?.();
      ref?.current?.setForm?.(userSocial);
    }
  };

  onUpdate(() => {
    if (isLoggedIn) {
      onAuthSuccess?.();
    }
  }, [isLoggedIn]);

  if (!visible) return null;

  return (
    <View variants={["flex", "column"]}>
      {!modal && <Text text={title} variants={["h1", "marginBottom:1"]} />}
      {!!subTitle && (
        <Text text={subTitle} variants={["p1", "marginBottom:4"]} />
      )}
      <View
        variants={[
          "column",
          type === "forgot" ? "marginBottom:2" : "marginBottom:4",
        ]}
      >
        {fields.map((field) => {
          const custom = typeof field !== "string";
          const name = custom ? field.name : field;
          const Component =
            custom && field.component ? field.component : TextInput;
          let extraProps = {};

          if (custom && field.extraProps) {
            extraProps = field.extraProps;
          }

          return (
            <Component
              variants={custom && field.variants}
              // @ts-ignore
              {...form.register(name)}
              {...extraProps}
            />
          );
        })}
      </View>
      {showSocial && (
        <View variants={["column", "gap:2", "marginBottom:4"]}>
          <Text
            text={"Or continue with"}
            variants={["p3", "alignSelfCenter"]}
          />
          <View variants={["row", "gap:2", "alignCenter", "justifyCenter"]}>
            <SocialButton platform={"google"} handleSocial={handleSocial} />
            <SocialButton platform={"facebook"} handleSocial={handleSocial} />
            <SocialButton platform={"apple"} handleSocial={handleSocial} />
          </View>
        </View>
      )}
      <View variants={["column", "gap:2"]}>
        <Button
          onPress={handleSubmit}
          disabled={!form.isValid}
          debugName={`submit ${type}`}
          text={submitText}
          variants={["marginHorizontal:auto", "fullWidth", "large"]}
        />

        {/* @ts-ignore */}
        {buttons.map((_props, i) => (
          <Button key={"button-modal" + i} {..._props} />
        ))}
      </View>
      {Footer}
    </View>
  );
});
