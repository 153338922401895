import { Widget } from "@/utils";
import { View, ActionIcon, Switch } from "@/components";
import { React, AppIcon, IconNames } from "@/app";
import { useBooleanToggle, useState, ReactStateProps } from "@codeleap/common";
import { useRef } from "react";

export type TIconWidget = Widget<
  ReactStateProps<"icon", AppIcon> & {
    iconsEnabled: boolean;
    toggleIcons: () => void;
    lastIcon: React.MutableRefObject<AppIcon>;
  },
  {
    initialIcon?: AppIcon;
  }
>;

const listIcon = IconNames.filter((icon) => !icon.includes("placeholder"));

function IconOption({ name, onPress, selected }) {
  return (
    <ActionIcon
      debugName={`Icon widget option ${name}`}
      onPress={onPress}
      icon={name}
      variants={[selected && "primary:outline"]}
    />
  );
}

export const IconWidget: TIconWidget = {
  Control: (props) => {
    const {
      setIcon,
      icon,
      label,
      iconsEnabled,
      lastIcon,
      toggleIcons,
      onVariantsChange,
    } = props;

    return (
      <>
        <Switch
          debugName="Switch icon widget"
          label={label}
          value={iconsEnabled}
          onValueChange={(v) => {
            setIcon(v ? lastIcon.current : null);
            toggleIcons();
            onVariantsChange?.();
          }}
        />

        {iconsEnabled && (
          <View variants={["gap:1", "wrap"]}>
            {listIcon.map((item) => (
              <IconOption
                key={item}
                onPress={() => {
                  setIcon(item);
                  lastIcon.current = item;
                }}
                name={item}
                selected={item === icon}
              />
            ))}
          </View>
        )}
      </>
    );
  },
  handle: ({ initialIcon = "edit" }) => {
    const [icon, setIcon] = useState<AppIcon>(initialIcon);
    const [iconsEnabled, toggleIcons] = useBooleanToggle(true);
    const lastIcon = useRef(icon);
    return { icon, setIcon, iconsEnabled, toggleIcons, lastIcon };
  },
};
