import {
  AppStatusOverlay,
  Page,
  DebugModal,
  Text,
  View,
  Button,
  Logo,
} from "@/components";
import { graphql, PageProps, navigate } from "gatsby";
import { variantProvider, AppImages } from "@/app";
import { APIClient } from "@/services";
import { onUpdate } from "@codeleap/common";

export const Overlays = () => (
  <View>
    <DebugModal />
    <AppStatusOverlay />
  </View>
);

const BREAKPOINT = "mid";

export default (props: PageProps) => {
  const { isLoggedIn, profile } = APIClient.Session.useSession();

  onUpdate(() => {
    if (isLoggedIn) {
      if (profile.hotel.verified) {
        navigate("/list/guests");
      } else {
        navigate("/auth/wait-approval");
      }
    }
  }, [isLoggedIn]);

  const logoImage = AppImages.AppLogoMobile;

  return (
    <Page
      centerContent={false}
      pageTitle={"Home"}
      showHeader={false}
      backgroundImageSource={AppImages.SingIn}
      showBackgroundImage
    >
      <View style={styles.wrapper}>
        <View
          style={styles.content}
          variants={[
            "column",
            "alignStart",
            "bg:neutral1",
            "padding:4",
            "border-radius:medium",
          ]}
        >
          <View>
            <Logo
              style={styles.logoImage}
              responsive={false}
              image={logoImage}
            />
            <Text text="BellHopp" variants={["h2", "bold", "marginLeft:1"]} />
          </View>
          <Text
            text="Welcome"
            variants={["hx", "bold", "textCenter", "marginBottom:1"]}
            responsiveVariants={{ [BREAKPOINT]: ["h1"] }}
          />

          <View
            variants={["fullWidth", "gap:2"]}
            responsiveVariants={{ [BREAKPOINT]: ["marginTop:auto", "column"] }}
          >
            <Button
              onPress={() => navigate("/auth/signup")}
              debugName={"Sign up"}
              variants={["bg:neutral10", "fullWidth"]}
            >
              <Text text="Sign Up" variants={["color:neutral1", "medium"]} />
            </Button>
            <Button
              onPress={() => navigate("/auth/login")}
              debugName={"Login"}
              variants={["action:dismiss", "gap:1"]}
            >
              <Text text="Login" variants={["color:primary5", "bold"]} />
            </Button>
          </View>
        </View>
      </View>
    </Page>
  );
};

const logoSize = 32;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      width: "100%",
      maxWidth: 560,
      height: "fit-content",

      [theme.media.down("mid")]: {
        ...theme.presets.fullView,
        padding: theme.spacing.value(2),
      },
    },
    image: {
      width: "45%",

      [theme.media.down("mid")]: {
        width: "100%",
      },
    },
    logoImage: {
      width: logoSize,
      marginBottom: theme.spacing.value(3),
      alignSelf: "flex-start",

      [theme.media.down("mid")]: {
        alignSelf: "center",
      },
    },
  }),
  true,
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
