import { createForm, yup } from "@codeleap/common";

export const createAnnouncement = () =>
  createForm("createAnnouncement", {
    title: {
      type: "text",
      label: "Title",
      placeholder: "e. g. Your laudry is ready!",
      validate: yup.string().max(60),
      required: true,
    },
    description: {
      type: "text",
      label: "Description",
      placeholder: "Type a description",
      validate: yup.string().max(1024),
      required: true,
    },
    url: {
      type: "text",
      label: "URL",
      placeholder: "https://www.example.com",
      validate: yup
        .string()
        .max(1024)
        .matches(
          /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
          "Invalid URL",
        ),
    },
    url_name: {
      type: "text",
      label: "URL Name",
      placeholder: "More details",
      validate: yup.string().max(1024),
    },
    image: {
      type: "file",
      required: true,
    },
    profile: {
      type: "list",
    },
    all_guests: {
      type: "checkbox",
    },
  });
