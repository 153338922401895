import { GridComposition, GridPresets } from "@codeleap/web";
import { variantProvider } from "../theme";

const createGridStyle = variantProvider.createVariantFactory<GridComposition>();

const defaultStyles = GridPresets;

export const AppGridStyles = {
  ...defaultStyles,
  default: createGridStyle((theme) => {
    return {
      ...defaultStyles,
      wrapper: {
        minHeight: "50vh",
        ...theme.presets.column,
        ...theme.presets.fullWidth,
        ...theme.presets.relative,
      },
      innerWrapper: {
        ...theme.presets.fullWidth,
      },
      refreshControl: {
        backgroundColor: theme.colors.neutral1,
        borderRadius: theme.borderRadius.rounded,
        zIndex: 2,
        ...theme.presets.absolute,
        left: "50%",
        transform: "translateX(-50%)",
        ...theme.spacing.padding(0.5),
        pointerEvents: "none",
      },
    };
  }),
  hiddenSeparator: createGridStyle((theme) => ({
    separator: {
      ...theme.presets.fullWidth,
      height: theme.values.innerSpacing.value,
    },
  })),
  lineSeparators: createGridStyle((theme) => ({
    separator: {
      height: theme.values.pixel,
      backgroundColor: theme.colors.neutral5,
    },
  })),
};
