import { Settings } from "../app/Settings";
import { LoggerAnalytics } from "@codeleap/common";

// import { getFirebase, withFirebase } from './firebase'
import { QueryKeys } from "./api/session/queryKeys";

export const AppAnalytics = new LoggerAnalytics.Analytics(
  {
    init: async () => {
      // const fb = await getFirebase()
      // const analytics = fb.analytics()
      // analytics.setAnalyticsCollectionEnabled(true)
      // analytics.logEvent('page_view', {})
    },
    onEvent: async (eventArgs) => {
      // const fb = await getFirebase()
      // const analytics = fb.analytics()
      // analytics.logEvent('app_action', eventArgs)
    },
    prepareData: () => {
      const extraData = {
        user: QueryKeys.me.getData(),
      };

      return extraData;
    },
    onInteraction: async (eventArgs) => {
      // const fb = await getFirebase()
      // const analytics = fb.analytics()
      // analytics.logEvent('user_interaction', eventArgs)
    },
  },
  Settings,
);
