import {
  ActionModal,
  Avatar,
  CardBase,
  Icon,
  Text,
  Tooltip,
  Touchable,
} from "@/components";
import { Theme, variantProvider } from "@/app";
import { useBooleanToggle } from "@codeleap/common";
import { APIClient } from "@/services";

const BREAKPOINT = "mid";

export const SignOut = () => {
  const [logoutModalIsOpen, setLogoutModalIsOpen] = useBooleanToggle(false);
  const { logout, profile } = APIClient.Session.useSession();

  const handleLogout = () => {
    setLogoutModalIsOpen();
    logout();
  };

  return (
    <Tooltip
      debugName="Session:tooltip-logout"
      variants={["white", "bare"]}
      openOnHover={false}
      closeOnClickOutside
      content={
        <CardBase
          debugName="Tooltip content"
          variants={["card:elevated", "padding:0"]}
        >
          <Touchable
            debugName="Edit announcement modal button"
            style={styles.tooltipOption}
            variants={[
              "paddingVertical:1",
              "paddingHorizontal:2",
              "justifyStart",
              "gap:1",
              "alignCenter",
            ]}
            onPress={setLogoutModalIsOpen}
          >
            <Icon
              name={"log-out"}
              size={Theme.values.iconSize[2]}
              color={Theme.colors.light.alert2}
            />

            <Text
              variants={["alignSelfStart", "color:alert2"]}
              text="Log out"
            />
          </Touchable>
        </CardBase>
      }
    >
      <Avatar
        debugName="Avatar:user"
        variants={["medium", "admin"]}
        responsiveVariants={{ [BREAKPOINT]: ["admin:small"] }}
        name={profile?.full_name}
      />

      <ActionModal
        debugName="Session:logout-modal"
        type="destructive"
        visible={logoutModalIsOpen}
        toggle={setLogoutModalIsOpen}
        buttons={{
          onAction: handleLogout,
          dismissText: "Cancel",
          actionIcon: "log-out",
          onActionText: "Log out",
        }}
        details={{
          title: "Are you sure you want to log out",
          description:
            "You will need to enter again to access the BellHopp platform if you log out right now.",
        }}
      />
    </Tooltip>
  );
};

const TOOLTIP_WIDTH = 168;

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    tooltipOption: {
      width: TOOLTIP_WIDTH,
    },
  }),
  true,
);
