import { IS_SSR } from "@/app";
import { AnyFunction, useIsomorphicEffect } from "@codeleap/common";
import { useRef } from "react";

export const useReadyTimer = (
  valid: boolean,
  handler: AnyFunction,
  timeout: number,
) => {
  const readyTimeout = useRef<NodeJS.Timeout>(null);

  const clear = () => {
    clearTimeout(readyTimeout.current);
    readyTimeout.current = null;
  };

  const trigger = () => {
    if (readyTimeout.current !== null) {
      clear();
    }

    if (!IS_SSR) {
      readyTimeout.current = setTimeout(async () => {
        await handler();
        clear();
      }, timeout);
    }
  };

  const cancel = () => {
    if (readyTimeout.current !== null) {
      clear();
    }
  };

  useIsomorphicEffect(() => {
    if (valid) {
      cancel();
    }
  }, [valid]);

  return {
    trigger,
    cancel,
    hasTimer: readyTimeout.current !== null,
  };
};
