import { AnyFunction, Indices } from "@codeleap/common";
import React, { useState } from "react";

export type Widget<T, Args = any> = {
  Control: React.FC<
    T & { label: string; args?: Args; onVariantsChange: AnyFunction }
  >;
  handle: (args?: Args) => T;
};

export type SelectorVariant<T extends Widget<any, any>> = {
  id: string;
  label: string;
  widget: T;
  args?: T extends Widget<any, infer Args> ? Args : never;
  onVariantsChange?: AnyFunction;
};

export type UseVariantSelectorReturn<
  T extends readonly SelectorVariant<any>[],
> = {
  handle: {
    [Property in Indices<T> as T[Property]["id"]]: ReturnType<
      T[Property]["widget"]["handle"]
    >;
  };
  Control: {
    Component: (props: any) => JSX.Element;
    props: any;
  }[];
  variantsChange: boolean;
};

export function useVariantSelector<T extends readonly SelectorVariant<any>[]>(
  variants: T,
): UseVariantSelectorReturn<T> {
  const Control: UseVariantSelectorReturn<T>["Control"] = [];
  const handle = {} as UseVariantSelectorReturn<T>["handle"];
  const [variantsChange, setVariantsChange] = useState(false);

  const onVariantsChange = React.useCallback(() => {
    setVariantsChange(true);

    setTimeout(() => setVariantsChange(false), 250);
  }, []);

  for (const variant of variants) {
    const props = variant.widget.handle(variant.args || {});

    Control.push({
      Component: variant.widget.Control,
      props: {
        ...props,
        label: variant.label,
        args: variant.args,
        onVariantsChange,
      },
    });

    handle[variant.id] = props;
  }

  return {
    Control,
    handle,
    variantsChange,
  };
}
